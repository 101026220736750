import { S25Util } from "../util/s25-util";
import { Cache } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";

export class LangService {
    @Timeout
    @Cache({ targetName: "LangService" })
    public static getLang() {
        return Promise.resolve(S25Util.extendDeep(LangService.DefaultLang.lang.data, LangService.LangAngular));
    }

    // @deprecated
    public static LangAngular = {
        // same for all instances; v23.0 has 'through' while v24.0 has Unicode 'EM DASH'
        // rangeSeparator: 'through'
        // from /usr/local/25live/releases/v24.0/app/application.xml
        rangeSeparator: String.fromCharCode(8212),
        // s25-my25live-lang.xml is missing this field; hard-code default
        div: {
            controls: {
                "s25-my25live": {
                    text: {
                        find_location_byname: "Find Location by Name:",
                        spaces_select_alert: "You must specify which Location to use.",
                        dashboard: {
                            help: "Help",
                            // comes from 25live/releases/v24.0/app/layout.xml
                            loading: "Loading Home Dashboard...",
                        },
                        // http://bugs.collegenet.com/jira/browse/STORY-1761
                        your_upcoming_events: "Your Upcoming Events",
                        // http://bugs.collegenet.com/jira/browse/STORY-1761
                        in_which_additional_role: "in which you have an additional role",
                        // http://bugs.collegenet.com/jira/browse/STORY-1761
                        instructor: "Instructor",
                        // http://bugs.collegenet.com/jira/browse/STORY-1761
                        no_upcoming_events: "You are not associated with any upcoming events",
                        // http://bugs.collegenet.com/jira/browse/ANG-192
                        check_smaller_capacity: "Do you want to check Matching Locations with a Smaller Capacity?",
                        s25_ng_check_larger_capacity: "Do you want to check Matching Locations with a Larger Capacity?",
                        s25_ng_no_larger_capacity:
                            "There are no Matching Locations with a Larger Capacity for us to search.",
                        fav_resources_calendar_link: "See when these resources are available",
                        space_date: "Date:",
                        search_organizations: "Search Organizations",
                    },
                    tooltips: {
                        space_date: "Click to select Location date.",
                    },
                },
                "s25-event_details": {
                    text: {
                        /** s25-event_detail.xml: <s:variable b:name="lDescriptionLabel" b:select="string($lEvent/r25:event_text[r25:text_type_id = '1']/r25:text_type_name)"/> */
                        description: "Description",
                        /** Folder */
                        folder: "Folder",
                        show: "Show",
                        more: "more...",
                        less: "less...",
                        s25ng_quantity: "Quantity:",
                        event_name_nonempty: "Please enter Event Name",
                        error: { event_locked: "An error occurred. This event is locked" },
                        attached_files: "Attached Files",
                        preference: {
                            event_preferences: "Event Preferences",
                            has_prefs: "has the following preferences",
                            default_profile_name: "This event",
                            show_edit_mode: "Edit",
                            hide_edit_mode: "View",
                            none: "none",
                            features: "Features",
                            partitions: "Partitions",
                            spaces: "Locations",
                            resources: "Resources",
                            edit: {
                                spaces: "Edit",
                                resources: "Edit",
                                features: "Edit",
                                partitions: "Edit",
                            },
                            delete: {
                                spaces: "Remove",
                                resources: "Remove",
                                features: "Remove",
                                partitions: "Remove",
                            },
                            error: {
                                delete: "There was an error removing Preferences",
                                update: "There was an error updating Preferences",
                            },
                        },
                        occurrence: {
                            event_occurrences: "Event Occurrences",
                            occurrences: "Occurrences",
                            profile: "Segment",
                            date: "Date",
                            start_time: "Start Time",
                            end_time: "End Time",
                            additional_details: "Additional Details",
                            slider: {
                                setup_dt: "Setup",
                                pre_dt: "Pre-Event",
                                start_dt: "Start",
                                end_dt: "End",
                                post_dt: "Post-Event",
                                takedown_dt: "Takedown",
                            },
                            delete_profile: {
                                text: "Delete this Segment",
                                error: {
                                    locked: "Error: This event is locked.",
                                },
                            },
                            preceding_occurrence: "preceding occurrence",
                            preceding_occurrences: "preceding occurrences",
                            following_occurrence: "following occurrence",
                            following_occurrences: "following occurrences",
                            merged_view: "Merged View",
                            separated_view: "Separated View",
                            submit: "Submit",
                        },
                        colander: {
                            checkall: "Select All",
                            matching: { all: "All", any: "Any" },
                            space: {
                                head: "Filter by Locations",
                                none: "No Location",
                                noneApproval: "No Location awaiting approval",
                                matching: { all: "All", any: "Any" },
                            },
                            res: {
                                head: "Filter by Resources",
                                none: "No Resource",
                                noneApproval: "No resource awaiting approval",
                            },
                        },
                    },
                },
                application: {
                    tooltips: {
                        no_perms_event: "You cannot currently edit this event",
                        section_collapse: "Collapse this section",
                        section_expand: "Expand this section",
                    },
                    editable: { error: "An error occurred" },
                },
                layout: {
                    text: {
                        noaccess: "You do not have access to view this component.",
                        loading: {
                            calendar_loading: "Calendar Loading...",
                            availability_loading: "Availability Loading...",
                        },
                        is_awaiting_approval: "is awaiting approval",
                        is_awaiting_approval_for_removal: "is awaiting approval for removal",
                        awaiting_approval: "awaiting approval",
                        cancelled: "This occurrence has been cancelled",
                        excluded: "This occurrence has been excluded",
                        locations: "locations",
                        resources: "resources",
                    },
                },
            },
            // search-criteria (basic, advanced)
            application: {
                select_all: "Select All",
                select_none: "Select None",
                event_start: "Event Start:",
                event_end: "Event End:",
                same_day: "the same day",
                commit: "OK",
                cancel: "Cancel",
                alert: { alert: "Alert", error: "Error", action: "OK", cancel: "Cancel", close: "Close" },
                google_maps: { see_on_google_maps: "Click to see on Google Maps" },
                yes: "Yes",
                no: "No",
                image_load_error: "An error occurred while loading the image",
            },
        },
    };

    //DefaultLang also lives in series_simple.lang.data for a WS Based titles
    private static DefaultLang = {
        lang: {
            data: {
                div: {
                    controls: {
                        bx_loading: {
                            text: {
                                stopping: "Stopping...",
                                stop_loading: "Stop Loading?",
                                loading: "Loading 25Live...",
                            },
                        },
                        "s25-my25live": {
                            text: {
                                tasks_you_assigned: "Tasks Assigned By You",
                                fav_loading: "Loading items of interest...",
                                quicksearch: "Quick Search",
                                required: "(required)",
                                draft: "Event Draft",
                                fav_spaces_calendar_link: "See when these locations are available",
                                dashboard: {
                                    save_button: "Save Dashboard",
                                    toolbox_title: "Trash Can",
                                    done_button: "Done",
                                    toolbox_prompt: "Customize Dashboard",
                                    edit_button: "Edit Dashboard",
                                    save_success: "Dashboard configuration saved successfully.",
                                    save_fail: "Dashboard configuration could not be saved.",
                                    toolbox_instructions_add:
                                        "To reposition or hide the Create an Event box, first hover over the icon until the cursor changes.",
                                    reset_confirm: "Are you sure you want to reset the dashboard?",
                                    toolbox_instructions_customize:
                                        "Drag an element from the dashboard to this area to hide it from view.",
                                    reset_button: "Reset Dashboard",
                                    toolbox_instructions_remove:
                                        "Drag a hidden element from this area to reposition it on your Dashboard.",
                                    toolbox_instructions_keyboard:
                                        "You can customize the dashboard using your keyboard by tabbing to a heading to select an element, then using the arrow keys to move it around the dashboard.",
                                },
                                events: "Events",
                                somewhere_between: "Somewhere between:",
                                tasks: "Tasks",
                                max_capacity: "Max Capacity:",
                                search_events: "Search Events",
                                too_short_search_term: "Search terms must be at least two characters long.",
                                loading: "Loading...",
                                tasks_on_agenda: "Tasks on Today's Agenda",
                                flagged_tasks: "Flagged Tasks",
                                find_availability:
                                    "<span><b>I know WHERE</b> my event should take place -- </span><span>help me<b> choose a time!</b></span>",
                                finding_locations: "Finding available locations based on your info...",
                                mark_resource_as_starred_1:
                                    'Throughout this site you can mark any Resource as "Starred" by clicking its ',
                                task_you_assigned: "Task Assigned By You",
                                no_starred_location_searches: "You do not have any Starred Location Searches!",
                                no_available_locations:
                                    "There are no locations available for that time that meet your criteria.",
                                mark_resource_as_starred_2: "icon.",
                                no_starred_resources: "You do not have any Starred Resources!",
                                more_info: "More info?",
                                get_tickets: "Get Tickets",
                                event_header_prefix: "Your Starred",
                                no_results: "No results!",
                                your_events: "Your Events",
                                event: "Event",
                                search_within: "Search within:",
                                popular: "Popular",
                                try_again: "Please revise your criteria and try again.",
                                no_matching_events: "No matching events for today!",
                                attendees_alert: "You must enter a valid value for the Number of Attendees.",
                                your_event_drafts: "Your Event Drafts",
                                show_locations_prompt: "Show me what's available!",
                                no_starred_events: "You do not have any Starred Events!",
                                end_time: "End Time:",
                                flagged_task: "Flagged Task",
                                whats_new: {
                                    button_hide: "Dismiss",
                                    header: "What's New in 25Live?",
                                },
                                no_starred_event_searches: "You do not have any Starred Event Searches!",
                                location_availability: "Show me this location's availability",
                                search_resources: "Search Resources",
                                use_this_location: "Use This Location",
                                location_collection_alert: "You must specify which Location Search to use.",
                                no_event_drafts: "No Event Drafts",
                                fav_location_searches: "Location Searches",
                                check_larger_capacity:
                                    "Do you want to check Matching Locations with a Larger Max Capacity?",
                                header_prefix: "Your Starred",
                                no_matching_locations:
                                    '<span>There are <b>No Matching Locations</b> with a Max Capacity between <b><span c:id="min">MIN</span></b> and <b><span c:id="max">MAX</span></b>.</span>',
                                search_locations: "Search Locations",
                                date: "Date:",
                                number_of_attendees: "Number of Attendees:",
                                reports: "Your Starred Reports",
                                featured: "Featured",
                                event_creation: "Find Available Locations",
                                outstanding_tasks: "Outstanding Tasks",
                                features: "Features:",
                                mark_location_as_starred_1:
                                    'Throughout this site you can mark any Location as "Starred" by clicking its ',
                                mark_location_as_starred_2: "icon.",
                                no_starred_resource_searches: "You do not have any Starred Resource Searches!",
                                in_which: "in which you are the",
                                no_starred_reports: "You do not have any Starred Reports!",
                                no_starred_locations: "You do not have any Starred Locations!",
                                start_time: "Start Time:",
                                checking_tasks: {
                                    searches: "Checking Tasks Searches...",
                                    checking: "Checking SEARCH_NAME...",
                                    outstanding: "Checking Outstanding Tasks...",
                                    flagged: "Checking Flagged Tasks...",
                                    assigned_by: "Checking Tasks Assigned By You...",
                                    agenda: "Checking Today's Agenda...",
                                },
                                all_locations: "All Locations",
                                mark_event_as_starred_1:
                                    'Throughout this site you can mark any Event as "Starred" by clicking its ',
                                select_your_location: "Select your location...",
                                happening_calendar_link: "Open a multi-day calendar of similar events",
                                mark_event_as_starred_2: "icon.",
                                no_search_term: "You have not entered a search term.",
                                default_quicksearch: "Keyword or Event Reference...",
                                outstanding_task: "Outstanding Task",
                                no_larger_capacity:
                                    "There are no Matching Locations with a Larger Max Capacity for us to search.",
                                fav_events: "Events",
                                attend_this_event: "Attend This Event",
                                scheduler: "Scheduler",
                                fav_spaces: "Locations",
                                select_daterange: "Select Date Range",
                                no_tasks: "You have No Tasks on Today's Agenda",
                                public: "Public",
                                create_button: "Create an Event",
                                no_events: "No Events",
                                try_browse: "Don't know the exact Location? Search by Location Groups...",
                                you_have: "You have",
                                find_location_prompt:
                                    "<span><b>I know WHEN</b> my event should take place -- </span><span>help me<b> find a location!</b></span>",
                                fav_resource_searches: "Resource Searches",
                                or: "OR",
                                one_task: "You have 1 Task on Today's Agenda",
                                select_your_location_collection: "Select a Location Search...",
                                go: "Go",
                                drafts: "Event Drafts",
                                happening_today: "What's Happening Today?",
                                requestor: "Requestor",
                                location_results:
                                    '<span>We searched Matching Locations with a Max Capacity between <b><span c:id="min">MIN</span></b> and <b><span c:id="max">MAX</span></b> and found <b><span c:id="tally">###</span> Available <span c:id="locations">LOCATIONS</span></b> for your selected Date and Time.</span>',
                                suggested: "Suggested",
                                no_event_access: "You do not have access to view events.",
                                enter_the_desired_date: "Enter the desired date and time for your event:",
                                fav_event_searches: "Event Searches",
                                locations: "Locations",
                                location: "Location",
                                finding_locations_warning:
                                    "(Depending on your search criteria, this may take a moment.)",
                                fav_resources: "Resources",
                                try_advanced_search: "Need more options? Try the Location Search...",
                                showing: "Showing",
                                event_creation_button: "Create an Event",
                            },
                            tooltips: {
                                edit_this_event: "Edit This Event",
                                more_info: "More info?",
                                remove_from_starred_event_searches: "Remove from Your Starred Event Searches",
                                refresh_section: "Refresh this Section",
                                quicksearch:
                                    "Enter whole word(s) or a start-of-word fragment to find events, locations, or resources.",
                                remove_from_starred_events: "Remove from Your Starred Events",
                                add_to_starred_event_searches: "Add to Your Starred Event Searches",
                                add_to_starred_resources: "Add to Your Starred Resources",
                                create_button:
                                    "Click to create an event and assign or request locations and resources, as required.",
                                events: "Access upcoming events that you are scheduling or requesting.",
                                remove_from_starred_resources: "Remove from Your Starred Resources",
                                date_range: "Click to select a start and end date.",
                                remove_from_starred_locations: "Remove from Your Starred Locations",
                                start_over: "Start Over",
                                add_to_starred_resource_searches: "Add to Your Starred Resource Searches",
                                drafts: "Access events you're scheduling or requesting that are still in the Draft state.",
                                remove_from_starred_location_searches: "Remove from Your Starred Location Searches",
                                new_event_using_location: "Create a New Event using this Location",
                                add_to_starred_location_searches: "Add to Your Starred Location Searches",
                                remove_from_starred_orgs: "Remove from Your Starred Organizations",
                                add_to_starred_locations: "Add to Your Starred Locations",
                                expand: "Expand this Section",
                                remove_from_starred_resource_searches: "Remove from Your Starred Resource Searches",
                                add_to_starred_events: "Add to Your Starred Events",
                                add_to_starred_reports: "Add to Your Starred Reports",
                                remove_from_starred_reports: "Remove from Your Starred Reports",
                                new_event_using_resource: "Create a New Event using this Resource",
                                collapse: "Collapse this Section",
                            },
                        },
                        "s25-task_overview": {
                            text: {
                                view_tasks: "View Tasks That Are:",
                                event_conflict_denials: "Tasks were denied because of a subsequent conflict.",
                                event_problem: "There was a problem with your event. Please try again later.",
                                private: "(Private)",
                                legend: "Legend:",
                                states: {
                                    declined: "Denied / Declined",
                                    various: "Various",
                                    cancelled: "Cancelled",
                                    active: "Active / In Progress",
                                    completed: "Granted / Completed",
                                },
                                flagged: "Flagged",
                                overdue: "Overdue",
                                assigned_all: "Assigned By You: All",
                                assignment_requests: "assignment requests",
                                results: {
                                    ignored: "Ignored",
                                    approved: "Approved",
                                    acknowledged: "Acknowledged",
                                    assigned: "Assigned",
                                    unassigned: "UnAssigned",
                                    completed: "Completed",
                                    denied: "Denied",
                                },
                                edit_todo: "Edit the details of this To Do.",
                                denied_declined: "Denied/Declined",
                                types: {
                                    todo: "To Do",
                                    authorization: "Authorization",
                                    vcal_todo: "vCal To Do",
                                    fyi: "FYI",
                                    assign: "Assign",
                                    unassign: "UnAssign",
                                },
                                outstanding: "Outstanding",
                                overview_of_tasks: "Overview of Tasks",
                                active_pending: "Active/Pending",
                                refresh: "Refresh",
                                completed: "Completed",
                                assigned_completed: "Assigned By You: Completed",
                                no_matching_tasks: "There are no tasks that meet the selected criteria.",
                                loading_tasks_that_are: "Loading Tasks That Are",
                                choose_tasks: "Choose Which Tasks To View",
                                assigned_denied: "Assigned By You: Denied",
                                event_conflict_denial: "This task was denied because of a subsequent conflict.",
                                choose_visible_columns: "Choose Visible Columns",
                                assigned_outstanding: "Assigned By You: Outstanding",
                                matching_tasks: "Matching Tasks",
                                approved_completed: "Approved/Completed",
                                cancelled: "Cancelled",
                                create_todo: "Create To Do Task",
                                actions: {
                                    deny: "Deny",
                                    see_event: "see event",
                                    acknowledge: "Acknowledge",
                                    approve: "Approve",
                                    ignore: "Ignore",
                                    none: "none",
                                    complete: "Complete",
                                    assign: "Assign",
                                    unassign: "UnAssign",
                                },
                                event_locked: "This event is currently locked. Please try again later.",
                            },
                            tooltips: {
                                help_with_view: "Help for this view",
                                columns: "Turn individual columns on and off to customize the display.",
                            },
                        },
                        "s25-todo_calendar": {
                            text: {
                                loading_todo_calendar: "Loading To Do Calendar..",
                                update_todo_calendar: "Update To Do Calendar",
                                viewing_todos: "Viewing To Do tasks",
                            },
                        },
                        "s25-publisher_admin": {
                            text: {
                                calendar_missing_note: "This calendar no longer exists. Its feeds can be deleted.",
                                pleasesignin: "Sign In using your 25Live Publisher credentials",
                                signingin: "Signing in to 25Live Publisher",
                                delete_confirm:
                                    'This will delete the "FEED_NAME" feed. Note that events already contained in the "CALENDAR_NAME" calendar will not be affected. Delete this feed?',
                                columns: {
                                    calendar: "Calendar",
                                    update_now: "Update Now?",
                                    calendar_id: "Calendar ID",
                                    query: "Query",
                                    status_msg: "Status Message",
                                    creation_date: "Creation Date",
                                    feed_uid: "Feed UID",
                                    type: "Type",
                                    delete: "Delete?",
                                    user: "25Live User",
                                    status: "Status",
                                    object_group: "Object/Group",
                                },
                                no_feeds: "No current or matching feeds.",
                                signout: "sign out",
                                refresh: "Refresh",
                                matching_feeds: "Matching Feeds",
                                title: "Publisher Overview",
                                choose_columns: "Choose Visible Columns",
                                calendar_missing:
                                    'The Publisher calendar "CALENDAR_NAME" no longer exists. Delete feed?',
                                signedinas: "signed in as",
                                password: "25Live Publisher Password",
                                fix_queries: "Fix Queries",
                                loadingdata: "Loading Publisher Data",
                                fixing_queries: "Fixing Queries",
                                removing_feed: "Removing Feed",
                                signin: "Sign In",
                                publisher: "25Live Publisher",
                                synchronizing_feed: "Synchronizing Feed",
                                signin_failure: "Sorry, failed to sign in to 25Live Publisher",
                                open: "Open",
                                username: "25Live Publisher Username",
                            },
                            tooltips: {
                                synchronize_this_feed: "Delete all feed tracking data and re-run the feed.",
                                delete_this_feed: "Delete this feed.",
                                fix_queries:
                                    "If you have older feeds with outdated database pointers, this will update and repair them. This is not the same as updating your feeds.",
                                open_publisher: "Open 25Live Publisher in new window",
                                refresh: "Refreshes the list of feeds below.",
                                choose_columns: "Turn individual columns on and off to customize the display.",
                                help_with_this_view: "Help with this view",
                            },
                        },
                        "s25-home_calendar": {
                            text: {
                                starred_event_searches: "Starred Event Searches",
                                no_matching_events: "No Matching Events",
                                starred_location_searches: "Starred Location Searches",
                                default_event_searches: "Pre-Defined Event Searches",
                                retrieving_events: "Retrieving Events...",
                                more_info: "More info?",
                                refresh: "Refresh",
                                dates: "Dates:",
                                loading: "Loading...",
                                loading_occurrences: "Loading Event Occurrences...",
                                no_event_access: "You do not have access to view events.",
                                featured_location_searches: "Featured Location Searches",
                                event_loading_stopped: "Event loading stopped.",
                                featured_event_searches: "Featured Event Searches",
                                load_events_prompt: "Load events?",
                                public_event_searches: "Public Event Searches",
                                showing: "Showing",
                                public_location_searches: "Public Location Searches",
                            },
                            tooltips: {
                                date_range: "Click to select a start and end date.",
                                help_with_this_view: "Help with this view",
                            },
                        },
                        "s25-search_create": {
                            text: {
                                private_text: "This Search is only available to you.",
                                layouts_any: "Layouts (Any):",
                                read_more: "Read more.",
                                private: "Private",
                                keyword_caution:
                                    "Note: Keyword searches, when used in combination with other criteria, are saved as a standalone search which is then included within the larger search. Consequently, two searches may be added.",
                                role: "Your Role:",
                                explain_text:
                                    "Saving these results as a search will store only the individual locations, resources, organizations, or events, not the criteria that they share.",
                                date_criteria: "Note: Date criteria cannot be added to 25Live searches.",
                                save_collection: "Save",
                                features_any: "Features (Any):",
                                save_as_collection: "Search Results",
                                too_many_results:
                                    "There are either too many results or no results to save as a search.",
                                public: "Public",
                                capacity_minimum: "Minimum Capacity:",
                                categories_all: "Categories (All):",
                                explain_text_search:
                                    "Saving the criteria as a search will store as part of the search all the individual selections you made to return the resulting objects.",
                                capacity_maximum: "Maximum Capacity:",
                                state: "State (Any):",
                                collection_text: "Save the following group of objects as a search:",
                                name_contains: "Keyword is:",
                                public_text: "This Search can be acccessed by other users.",
                                layouts_all: "Layouts (All):",
                                categories_any: "Categories (Any):",
                                types: "Types (Any):",
                                enter_name: "Search Name:",
                                starred_searches_label: "Add this to Your Starred Searches.",
                                search_type_prompt: "Save this search using:",
                                btn_save: "Save Search",
                                save_as_search: "Search Criteria",
                                organizations: "Organizations (Any):",
                                features_all: "Features (All):",
                                cabinet: "Cabinet:",
                                search_text: "Save the following criteria as a search:",
                            },
                            tooltips: {
                                click_to_select_date: "Click to select a date.",
                                help_with_this_view: "Help for this view",
                            },
                        },
                        "s25-subtabbar": {
                            text: {
                                todo_calendar: "To Do Calendar",
                                reports: "Reports",
                                agenda_tasks: "Tasks Agenda",
                                more_info: "More info?",
                                starred_reports: "Starred Reports",
                                favorite_reports: "Starred Reports",
                                close_all: "Close All Individual",
                                event: "Event",
                                new_event: "New Event...",
                                events: "Events",
                                dashboard: "Dashboard",
                                search_for: "Search For",
                                calendar: "Calendar",
                                advanced_search: "Advanced ABC Search",
                                pre_defined_searches: "Pre-Defined ABC Searches",
                                events_being_edited: "Events Being Edited",
                                individual: "Individual",
                                events_being_created: "Events Being Created",
                                resource: "Resource",
                                other_reports: "Other Reports",
                                resources: "Resources",
                                search_tasks: "Advanced Task Search",
                                editing: "Editing",
                                space_reports: "Location Reports",
                                event_reports: "Event Reports",
                                organization: "Organization",
                                creating: "Creating",
                                organizations: "Organizations",
                                location: "Location",
                                overview_tasks: "Overview of Tasks",
                                locations: "Locations",
                                resource_reports: "Resource Reports",
                            },
                            tooltips: {
                                view_tasks: "View tasks that require your attention.",
                                details_tip: "View currently open ABC.",
                                home_calendar: "Make the Calendar your view preference for the Home tab.",
                                create_event:
                                    "Create an event and assign or request locations and resources, as required.",
                                reports_tip: "Run, print, or email reports for specified ABC.",
                                home_dashboard: "Make the Dashboard your view preference for the Home tab.",
                                search_tip: "Specify your own criteria to perform a search.",
                                view_tasks_date_range: "View events with tasks for a particular date range.",
                                browse_tip:
                                    "Choose among existing searches--including those created and saved by you--to find ABC of interest.",
                            },
                        },
                        "s25-resource_browse": {
                            text: {
                                date: "Date:",
                                search_groupings: "Search Groupings",
                                hide_search_criteria: "Hide",
                                index_keyword: "Index",
                                someone_elses: "You can only delete searches you created.",
                                retrieving_resources: "Retrieving Resources...",
                                search_renamed: "was successfully renamed.",
                                open_resources: "Individual Resources You Have Open",
                                fav: "Starred (Remove from Starred?)",
                                no_collections: "No Searches",
                                rename_someone_elses: "You can only rename searches you created.",
                                select_today: "Select Today",
                                rename_search: "Rename Search",
                                browse_header: "Pre-Defined Resource Searches",
                                no_search_selected: "You have not yet selected a Resource Search.",
                                same_day_view: "Same-Day Event View:",
                                no_resource_categories: "No Resource Categories",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                edit_resource_collection: "Rename Resource Search",
                                no_resources: "There are no resources that meet the selected criteria.",
                                choose_collection_name: "Please choose a name for your search.",
                                list: "List",
                                index_all_collection: "All of Your Searches",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                save_problem: "There was a problem saving your search. Please try again later.",
                                name: "Name",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                browse_instructions: "Select a Search Grouping (left) to browse existing searches.",
                                delete_search: "Delete Search",
                                no_access_msg: "You have not selected any resources to view.",
                                starred_resources: "Your Starred Resources",
                                columns: "Choose Visible Columns",
                                matching_resources: "Matching Resources",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                availability: "Availability",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                choose_search_grouping:
                                    "Choose a Search Grouping on the left, then select a Search from the resulting list on the right.",
                                popular_resources: "Popular Resources",
                                categories: "Categories",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                index_collection: "Your Starred Searches",
                                show_events_for_resource: "(Show Events for this Resource)",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                index_default_collection: "Public Searches",
                                viewing_results_for: "You are viewing results for",
                                index_category: "Categories",
                                overlapping: "Overlapping",
                                custom_groups: "Pre-Defined Groups",
                            },
                            tooltips: {
                                next_page: "Next Page",
                                add: "Add",
                                click_select_date: "Click to select a date.",
                                help_with_view: "Help for this view",
                                last_page: "Last Page",
                                first_page: "First Page",
                                columns: "Turn individual columns on and off to customize the display.",
                                sort_by_name: "Sort by Name",
                                previous_page: "Previous Page",
                                title: "Title:",
                                remove: "Remove",
                            },
                        },
                        "s25-space_search_advanced": {
                            text: {
                                date: "Date:",
                                search_groupings: "Search Groupings",
                                hide_search_criteria: "Hide",
                                no_locations: "There are no locations that meet the selected criteria.",
                                index_keyword: "Index",
                                someone_elses: "You can only delete searches you created.",
                                highlight_location: "Highlight Location:",
                                custom_groups_label: "Pre-Defined Groups",
                                search_renamed: "was successfully renamed.",
                                fav: "Starred (Remove from Starred?)",
                                formal_name: "Formal Name",
                                matching_locations: "Matching Locations",
                                index_layout: "Layouts",
                                no_collections: "No Searches",
                                index_feature: "Features",
                                rename_someone_elses: "You can only rename searches you created.",
                                select_today: "Select Today",
                                restart_link: "(Show Events for this Location)",
                                rename_search: "Rename this search",
                                same_day_view: "Same-Day Event View:",
                                multi_restart_link: "(Show Events for this Date)",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                choose_collection_name: "Please choose a name for your search.",
                                loading: "Loading...",
                                list: "List",
                                index_capacity: "Capacities",
                                index_all_collection: "All of Your Searches",
                                search_already_exists:
                                    "This search name already exists, please choose a different search name.",
                                multiday_availability: "Multi-Day Availability",
                                name: "Name",
                                showing_weekdays: "Weekdays:",
                                retrieving_locations: "Retrieving Locations...",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                advanced_search_header: "Advanced Location Search",
                                browse_instructions:
                                    "Choose a Search Grouping on the left, then select a Search from the resulting list on the right.",
                                delete_search: "Delete this search",
                                no_access_msg: "You have not selected any locations to view.",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                availability: "Availability",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                index_collection: "Your Starred Searches",
                                no_weekdays: "You must select at least one Weekday to display results.",
                                criteria_empty: "You have not yet selected a Location Search.",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                index_default_collection: "Public Searches",
                                viewing_results_for: "You are viewing results for",
                                index_category: "Categories",
                                choose_visible_columns: "Choose Visible Columns",
                                help_with_view: "Help for this view",
                                edit_space_collection: "Rename Location Search",
                                overlapping: "Overlapping",
                                and_greater: "and greater",
                                custom_groups: {
                                    favorites: {
                                        "logged-out": "Suggested Locations",
                                        "logged-in": "Your Starred Locations",
                                        web: "Popular Locations",
                                    },
                                    open: "Individual Locations You Have Open",
                                },
                                update_availability: "Update Availability",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                next_page: "Next Page",
                                turn_off_columns: "Turn individual columns on and off to customize the display.",
                                last_page: "Last Page",
                                first_page: "First Page",
                                select_weekdays: "Confine your search results to selected days of the week.",
                                click_to_select_date: "Click to select a date.",
                                publisher:
                                    "Send events occurring in the locations displayed below to the 25Live Publisher.",
                                previous_page: "Previous Page",
                                title: "Title:",
                                help_with_this_view: "Help with this view",
                            },
                        },
                        "s25-organization_search_advanced": {
                            text: {
                                advanced_search_header: "Advanced Organization Search",
                                no_access_msg: "You have not selected any organizations to view.",
                                index_collection: "Your Starred Searches",
                                retrieving_organizations: "Retrieving Organizations...",
                                loading_events: "Loading Events...",
                                search_deleted: "was successfully deleted.",
                                delete_problem: "There was a problem deleting your search.",
                                someone_elses: "You can only delete searches you created.",
                                index_default_collection: "Public Searches",
                                index_all_collection: "All of Your Searches",
                                no_organizations: "There are no organizations that meet the selected criteria.",
                                matching_organizations: "Matching Organizations",
                                no_collections: "No Searches",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: "",
                        },
                        "s25-event_browse": {
                            text: {
                                search_groupings: "Search Groupings",
                                popular_events: "Featured Events",
                                index_keyword: "Index",
                                events_in_starred_locations: "Events In Your Starred Locations",
                                type: "Type",
                                index_type: "Types",
                                someone_elses: "You can only delete searches you created.",
                                reference: "Reference",
                                search_problem_org:
                                    "You are not currently associated with an organization. Please contact your system administrator.",
                                events_using_starred_resources: "Events Using Your Starred Resources",
                                index_event_collection_default: "Public Event Searches",
                                matching_events: "Matching Events",
                                loading_occurrences: "Loading Event Occurrences...",
                                search_renamed: "was successfully renamed.",
                                index_event_collection_all: "All of Your Event Searches",
                                index_space_collection: "Your Starred Location Searches",
                                events_sponsored_by_starred_orgs: "Events Sponsored By Your Starred Organizations",
                                fav: "Starred (Remove from Starred?)",
                                state: "State",
                                events_requested: "Events You Have Requested",
                                no_collections: "No Searches",
                                no_recent_events: "No individual events have been recently viewed.",
                                events_with_tasks: "Events With Tasks Assigned To You",
                                rename_someone_elses: "You can only rename searches you created.",
                                rename_search: "Rename Search",
                                use_location_tab: "Please edit Location searches on the Your Location Searches tab.",
                                drafts_requested: "Event Drafts You Have Requested",
                                retrieving_events: "Retrieving Events...",
                                browse_header: "Pre-Defined Event Searches",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                choose_collection_name: "Please choose a name for your search.",
                                loading: "Loading...",
                                search_problem_alert: "There was a problem with your search.",
                                list: "List",
                                your_role: "Your Role",
                                index_space_collection_default: "Public Location Searches",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                name: "Name",
                                index_organization: "Organizations",
                                events_attending: "Events You Are Attending",
                                events_additional_role: "Events in which you have an additional role",
                                actions: "Actions",
                                index_cabinet: "Cabinets",
                                browse_instructions: "Select a Search Grouping (left) to browse those searches.",
                                delete_search: "Delete Search",
                                no_access_msg: "You have not selected any events to view.",
                                index_role: "Roles",
                                columns: "Choose Visible Columns",
                                retrieving_criteria: "Retrieving Criteria...",
                                hot_events: "Hot Events",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                get_tickets: "Get Tickets",
                                title: "Title",
                                no_event_search: "You have not yet selected an Event Search.",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                events_scheduling: "Events You Are Scheduling",
                                space_search_instructions:
                                    "When you run a Location Search, your results will be the events to which those locations are assigned.",
                                events_instructor: "Events in which you are the Instructor",
                                index_event_collection_keyword: "Supplementary Keyword Searches",
                                events_sponsored_by_contact_orgs:
                                    "Events Sponsored By Organizations To Which You Belong",
                                categories: "Categories",
                                edit_event_collection: "Rename Event Search",
                                start_date: "Start Date",
                                calendar: "Calendar",
                                index_collection: "Your Starred Event Searches",
                                index_state: "States",
                                index_space_collection_all: "All of Your Location Searches",
                                drafts_scheduling: "Event Drafts You Are Scheduling",
                                refresh: "Refresh",
                                individual_events: "Individual Events You Have Open",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                featured_events: "Featured Events",
                                viewing_results_for: "You are viewing results for",
                                your_starred_events: "Your Starred Events",
                                index_category: "Categories",
                                organization: "Organization",
                                report: "Print a Report",
                                custom_groups: "Pre-Defined Groups",
                                attend_event: "Attend This Event",
                            },
                            tooltips: {
                                add_to_starred: "Add to Your Starred Events.",
                                edit_event: "Edit this event.",
                                columns: "Turn individual columns on and off to customize the display.",
                                publisher: "Send events displayed below to the 25Live Publisher.",
                                remove_from_starred: "Remove from Your Starred Events.",
                                help_with_this_view: "Help for this view",
                            },
                        },
                        "s25-organization_details": {
                            text: {
                                no: "No",
                                columns: "Choose Visible Columns",
                                rating: "Rating",
                                not_fav: "Not Starred (Add to Starred?)",
                                none: "none",
                                type: "Type",
                                retrieving_info: "Retrieving Information...",
                                default_view_details:
                                    'Make "Details" my default view when loading individual Organizations',
                                general: "General",
                                default_view_calendar:
                                    'Make "Calendar" my default view when loading individual Organizations',
                                details: "Details",
                                fav: "Starred (Remove from Starred?)",
                                partition_preferences: "Partition Preferences",
                                categories: "Categories",
                                close: "Close this Organization",
                                schedule25_key: "Schedule25 Key",
                                calendar: "Calendar",
                                comments: "Comments",
                                create_event: "Create an Event sponsored by this Organization",
                                yes: "Yes",
                                refresh: "Refresh",
                                dates: "Dates:",
                                nonexistent_msg: "This organization does not exist, or has been deleted.",
                                click_here: "Click Here",
                                list: "List",
                                denial_msg: "You do not have permission to view this organization.",
                                occurring: "occurring",
                                get_tix: "Get Tickets",
                                accounting_code: "Accounting Code",
                                address_administrative: "Administrative Address",
                                associated_contacts: "Associated Contacts",
                                no_reservations_for: "There are no reservations for",
                                default_view_list: 'Make "List" my default view when loading individual Organizations',
                                matching_reservations: "Matching Reservations",
                                address_billing: "Billing Address",
                                attributes: "Attributes",
                                primary_contact: "Primary",
                                actions: "Actions...",
                                attend_event: "Attend This Event",
                            },
                            tooltips: {
                                loading_summary: "Loading Organization Summary...",
                                help_with_view: "Help with this view",
                                view_details: "Click to view organization details and check availability",
                                close_view: "Close this view.",
                            },
                        },
                        "s25-establish_relationships": {
                            text: {
                                or: "or",
                                relationship_created: "Event Relationship Created!",
                                same_event: "An event cannot be related to itself.",
                                choose_from_starred: "Choose from Starred Events",
                                has_relationships: "has a content relationship with the following event(s):",
                                search_events: "Search for Events",
                                choose_related_events: "Find events to relate:",
                                finished: "Finished",
                                has_no_relationships:
                                    "does not currently have a content relationship with any other events.",
                                search_too_short: "Keyword searches must be at least 2 characters long.",
                                none: "none",
                                loading: "Loading...",
                                choose_from_recent: "Choose from Recently Viewed Events",
                                no_results: "No Results",
                                establish_relationships: "Manage Related Events",
                                remove: "remove?",
                                undo: "Undo?",
                                relationship_removed: "Event Relationship Removed!",
                                relate_selected: "Relate Selected",
                                save_problem:
                                    "There was a problem with saving your event relationship. Please try again later.",
                                no_recently_viewed: "No Recently Viewed Events",
                                missing_event: "You have not selected an event.",
                                relationships_intro: {
                                    hide: "Hide this Guide",
                                    header: "About Event Relationships",
                                    for_more: "For more information on Event Relationships,",
                                    click_here: "click here",
                                    content:
                                        "Use this functionality to track events that are related in a way that's meaningful to you. This type of relationship does not affect any of the data.",
                                },
                                event_locked: "This event is currently locked. Please try again later.",
                            },
                            tooltips: {
                                help_with_related_events: "Help with Related Events",
                            },
                        },
                        "s25-calendar": {
                            text: {
                                create_event: "Create an event",
                                more_info: "More info?",
                                get_tickets: "Get Tickets",
                                click_here: "Click Here",
                                more_locations: "more locations...",
                            },
                            tooltips: {
                                create_event: "Click here to create an event on this date.",
                                view_only_this_day: "View only this day",
                                view_next_day: "View Next Day",
                                view_previous_day: "View Previous Day",
                                click_here: "Click Here",
                            },
                        },
                        "s25-bind_reservations": {
                            text: {
                                binding_created: "Event Binding Created!",
                                select_reservation: "Select a Reservation...",
                                already_bound: "is already bound to the reservation you selected.",
                                same_event: "An event cannot be related to itself.",
                                choose_from_starred: "Choose from Starred Events",
                                search_too_short: "Keyword searches must be at least 2 characters long.",
                                none: "none",
                                choose_from_recent: "Choose from Recently Viewed Events",
                                no_results: "No Results",
                                remove: "remove?",
                                bindings_intro: {
                                    hide: "Hide this Guide",
                                    header: "About Event Bindings",
                                    for_more: "For more information on Event Bindings,",
                                    click_here: "click here",
                                    content:
                                        'Events that are "bound" in 25Live must have the same location(s) assigned, although they may occur at either the same, or different, dates/times. For example, you might use bindings for cross-listed classes. All occurrences of all events in a binding must use the same location(s). Editing a bound event to change its location assignment(s) automatically updates the other events in the binding to use the same location(s).',
                                },
                                is_not_bound: "is not currently bound to any other events.",
                                undo: "Undo?",
                                is_bound: "is bound to the following event(s):",
                                binding_removed: "Event Binding Removed!",
                                bind_events: "Manage Bindings",
                                has_multiple_reservations:
                                    "has more than one reservation. Please select the reservation whose bindings you want to manage.",
                                or: "or",
                                choose_events: "Find Events to Bind",
                                search_events: "Search for Events",
                                finished: "Finished",
                                loading: "Loading...",
                                no_recently_viewed: "No Recently Viewed Events",
                                save_problem:
                                    "There was a problem with saving your event binding. Please try again later.",
                                bind_selected: "Bind Selected",
                                missing_event: "You have not selected an event.",
                                event_locked: "This event is currently locked. Please try again later.",
                            },
                            tooltips: {
                                help_with_bound_events: "Help with Related Events",
                                insufficient_permissions:
                                    "You do not have permission to create bindings with this event.",
                            },
                        },
                        bx_select: {
                            text: {
                                clear_all: "Clear All",
                                select_all: "Select All",
                            },
                        },
                        "s25-space_browse": {
                            text: {
                                date: "Date:",
                                search_groupings: "Search Groupings",
                                hide_search_criteria: "Hide",
                                no_locations: "There are no locations that meet the selected criteria.",
                                index_keyword: "Index",
                                someone_elses: "You can only delete searches you created.",
                                highlight_location: "Highlight Location:",
                                custom_groups_label: "Pre-Defined Groups",
                                search_renamed: "was successfully renamed.",
                                fav: "Starred (Remove from Starred?)",
                                formal_name: "Formal Name",
                                matching_locations: "Matching Locations",
                                index_layout: "Layouts",
                                no_collections: "No Searches",
                                index_feature: "Features",
                                rename_someone_elses: "You can only rename searches you created.",
                                select_today: "Select Today",
                                restart_link: "(Show Events for this Location)",
                                rename_search: "Rename this search",
                                browse_header: "Pre-Defined Location Searches",
                                same_day_view: "Same-Day Event View:",
                                multi_restart_link: "(Show Events for this Date)",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                choose_collection_name: "Please choose a name for your search.",
                                loading: "Loading...",
                                list: "List",
                                index_capacity: "Capacities",
                                index_all_collection: "All of Your Searches",
                                search_already_exists:
                                    "This search name already exists, please choose a different search name.",
                                multiday_availability: "Multi-Day Availability",
                                name: "Name",
                                showing_weekdays: "Weekdays:",
                                retrieving_locations: "Retrieving Locations...",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                browse_instructions: "Select a Search Grouping (left) to browse existing searches.",
                                delete_search: "Delete this search",
                                no_access_msg: "You have not selected any locations to view.",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                availability: "Availability",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                index_collection: "Your Starred Searches",
                                no_weekdays: "You must select at least one Weekday to display results.",
                                criteria_empty: "You have not yet selected a Location Search.",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                index_default_collection: "Public Searches",
                                viewing_results_for: "You are viewing results for",
                                index_category: "Categories",
                                choose_visible_columns: "Choose Visible Columns",
                                help_with_view: "Help for this view",
                                edit_space_collection: "Rename Location Search",
                                overlapping: "Overlapping",
                                and_greater: "and greater",
                                custom_groups: {
                                    favorites: {
                                        "logged-out": "Suggested Locations",
                                        "logged-in": "Your Starred Locations",
                                        web: "Popular Locations",
                                    },
                                    open: "Individual Locations You Have Open",
                                },
                                update_availability: "Update Availability",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                next_page: "Next Page",
                                turn_off_columns: "Turn individual columns on and off to customize the display.",
                                last_page: "Last Page",
                                first_page: "First Page",
                                select_weekdays: "Confine your search results to selected days of the week.",
                                click_to_select_date: "Click to select a date.",
                                publisher:
                                    "Send events occurring in the locations displayed below to the 25Live Publisher.",
                                previous_page: "Previous Page",
                                title: "Title:",
                                help_with_this_view: "Help with this view",
                            },
                        },
                        "s25-space_details": {
                            text: {
                                date: "Date",
                                instructions: "Default Instructions",
                                none: "none",
                                availability_weekly: "Availability (Weekly)",
                                layouts: "Layouts",
                                pre_event: "Pre-Event",
                                reference: "Reference",
                                post_event: "Post-Event",
                                related_spaces: "Related Spaces",
                                features: "Features",
                                schedulers: "Location Scheduler",
                                default_view_calendar:
                                    'Make "Calendar" my default view when loading individual Locations',
                                fav: "Starred (Remove from Starred?)",
                                details: "Details",
                                show_events_for_date: "(Show Events for this Date)",
                                images: "Images",
                                starting_week: "Starting Week",
                                create_event: "Create an Event in this Location",
                                max_capacity: "Max Capacity",
                                same_day_view: "Same-Day Event View:",
                                head_count: "Head Count",
                                dates: "Dates:",
                                nonexistent_msg: "This location does not exist, or has been deleted.",
                                list: "List",
                                viewing_layout_image: "Layout Image",
                                get_tix: "Get Tickets",
                                takedown: "Takedown",
                                default_view_list: 'Make "List" my default view when loading individual Locations',
                                number_of_weeks: "Number of Weeks",
                                event_times: "Event Times",
                                showing_weekdays: "Weekdays:",
                                publisher: "Send Events in this Location to the 25Live Publisher",
                                availability_daily: "Availability (Daily)",
                                guide_prompt: "What is this view?",
                                actions: "Actions...",
                                layout_no_image: "The default layout does not have an associated image or diagram.",
                                columns: "Choose Visible Columns",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                availability: "Availability",
                                view_availability: "View this location's availability!",
                                default_view_availability_weekly:
                                    'Make "Availability (Weekly)" my default view when loading individual Locations',
                                default_view_details:
                                    'Make "Details" my default view when loading individual Locations',
                                retrieving_info: "Retrieving Information...",
                                capacity: "Capacity",
                                default: "(default)",
                                viewing_layout_diagram: "Layout Diagram",
                                categories: "Categories",
                                close: "Close this Location",
                                map: "Map",
                                default_view_availability:
                                    'Make "Availability (Daily)" my default view when loading individual Locations',
                                start_date: "Start Date",
                                calendar: "Calendar",
                                no_report_msg: "No report is available",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                comments: "Comments",
                                subscribe: "Subscribe to Events in this Location",
                                no_weekdays: "You must select at least one Weekday to display results.",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                click_here: "Click Here",
                                denial_msg: "You do not have permission to view this location.",
                                default_layout: "The default layout",
                                occurring: "occurring",
                                no_image: "This location does not have any associated images or instructions.",
                                no_reservations_for: "There are no reservations for",
                                overlapping: "Overlapping",
                                matching_reservations: "Matching Reservations",
                                organization: "Organization",
                                report_not_available: "This report is not available",
                                report: "Print a Report for this Location",
                                setup: "Setup",
                                attributes: "Attributes",
                                update_availability: "Update Availability",
                                attend_event: "Attend This Event",
                            },
                            tooltips: {
                                date_range: "Click to select a start and end date.",
                                select_number_of_weeks: "Select how many weeks to view.",
                                create_event: "Create an event in this location on this date.",
                                columns: "Turn individual columns on and off to customize the display.",
                                select_starting_week: "Select the first week to view.",
                                last_page: "Last Page",
                                in_related: "In related location",
                                view_details: "Click to view location details and check availability",
                                previous_page: "Previous Page",
                                close_view: "Close this view.",
                                title: "Title:",
                                takedown: "Takedown:",
                                reference: "Reference:",
                                next_page: "Next Page",
                                help_with_view: "Help for this view",
                                reserved: "Reserved:",
                                first_page: "First Page",
                                sort_by_date: "Sort by Date",
                                organization: "Organization:",
                                select_weekdays: "Confine your search results to selected days of the week.",
                                reservation: "Reservation:",
                                setup: "Setup:",
                            },
                        },
                        "s25-related_events_edit": {
                            text: {
                                button_all_selected: "Edit All Selected Events",
                                button_only_current: "Only Edit This Event",
                                or_text: "or",
                                has_related_events: "has Related Events.",
                                edit_related_prompt: "Do you want to edit those as well?",
                                header: "Edit Related Events",
                            },
                            tooltips: {
                                help: "Help with Editing Related Events",
                            },
                        },
                        "s25-profile_selection": {
                            text: {
                                select_profile: "Edit Reservation",
                                instructions:
                                    'The event you have selected to edit was created in R25 with multiple "reservations." You can edit only one reservation at a time in 25Live.Choose the reservation to edit:',
                                select: "Select",
                                profile: "Reservation",
                                description: "Description",
                                header: "Choose a Reservation to Edit",
                            },
                            tooltips: "",
                        },
                        "s25-task_search_advanced": {
                            text: {
                                date: "Date:",
                                search_groupings: "Search Groupings",
                                hide_search_criteria: "Hide",
                                index_keyword: "Index",
                                someone_elses: "You can only delete searches you created.",
                                search_renamed: "was successfully renamed.",
                                fav: "Starred (Remove from Starred?)",
                                no_collections: "No Searches",
                                rename_someone_elses: "You can only rename searches you created.",
                                select_today: "Select Today",
                                rename_search: "Rename Search",
                                no_search_selected: "You have not yet selected a Task Search.",
                                same_day_view: "Same-Day Event View:",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                edit_resource_collection: "Rename Resource Search",
                                choose_collection_name: "Please choose a name for your search.",
                                list: "List",
                                index_all_collection: "All of Your Searches",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                save_problem: "There was a problem saving your search. Please try again later.",
                                matching_tasks: "Matching Tasks",
                                name: "Name",
                                retrieving_tasks: "Retrieving Tasks...",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                advanced_search_header: "Advanced Task Search",
                                browse_instructions: 'Browse existing searches, or click the "New Search" button.',
                                delete_search: "Delete Search",
                                no_access_msg: "You have not selected any tasks to view.",
                                columns: "Choose Visible Columns",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                availability: "Availability",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                no_tasks: "There are no tasks that meet the selected criteria.",
                                choose_search_grouping:
                                    "Choose a Search Grouping on the left, then select a Search from the resulting list on the right.",
                                need_state: "You must choose at least one task state.",
                                categories: "Categories",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                index_collection: "Your Starred Searches",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve tasks...",
                                separated: "Separated",
                                need_type: "You must choose at least one task type.",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                index_default_collection: "Public Searches",
                                viewing_results_for: "You are viewing results for",
                                index_category: "Categories",
                                overlapping: "Overlapping",
                                custom_groups: "Pre-Defined Groups",
                            },
                            tooltips: {
                                next_page: "Next Page",
                                add: "Add",
                                click_select_date: "Click to select a date.",
                                help_with_view: "Help for this view",
                                last_page: "Last Page",
                                first_page: "First Page",
                                columns: "Turn individual columns on and off to customize the display.",
                                sort_by_name: "Sort by Name",
                                previous_page: "Previous Page",
                                title: "Title:",
                                remove: "Remove",
                            },
                        },
                        "s25-space_search": {
                            text: {
                                date: "Date:",
                                category_additional_ellipsis: "Choose an additional Category...",
                                no_locations: "There are no locations that meet the selected criteria.",
                                collection_saved_succesfully:
                                    "was successfully saved. You can view and run it by clicking Your Location Searches.",
                                search_by_keyword: "Search by Keyword:",
                                category_all: "All Categories",
                                highlight_location: "Highlight Location:",
                                sentence_nav_header: "Search For Locations",
                                layout_additional_ellipsis: "Choose an additional Layout...",
                                feature_additional_ellipsis: "Choose an additional Feature...",
                                feature_loading: "Loading Features...",
                                matching_locations: "Matching Locations",
                                between: "between",
                                more_search_options: "More Search Options",
                                button_clear: "Clear",
                                select_today: "Select Today",
                                button_go: "GO",
                                too_short_search_term: "Search terms must be at least two characters long.",
                                same_day_view: "Same-Day Event View:",
                                multi_restart_link: "(Show Events for this Date)",
                                dates: "Dates:",
                                choose_collection_name: "Please choose a name for your search.",
                                loading: "Loading...",
                                list: "List",
                                layout_loading: "Loading Layouts...",
                                show_events_location: "(Show Events for this Location)",
                                layout_additional: "Choose an additional Layout?",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                save_problem: "There was a problem saving your search. Please try again later.",
                                multiday_availability: "Multi-Day Availability",
                                name: "Name",
                                showing_weekdays: "Weekdays:",
                                category_additional: "Choose an additional Category?",
                                no_dates_text: "Date criteria cannot be saved for any searches in 25Live.",
                                retrieving_locations: "Retrieving Locations...",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                or_choose_from: "...or choose from",
                                no_search_term: "You have not entered a search term.",
                                no_access_msg: "You have not selected any locations to view.",
                                availability: "Availability",
                                capacity: "Capacity",
                                feature: "Feature:",
                                feature_all: "All Features",
                                and: "and",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                or: "OR",
                                no_weekdays: "You must select at least one Weekday to display results.",
                                button_start_over: "START OVER",
                                refresh: "Refresh",
                                default_search_text: "Enter a Search Term...",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                layout_all: "All Layouts",
                                no_criteria_provided:
                                    "Please enter or select some criteria before starting your search.",
                                "pre-defined_searches": "Your Location Searches",
                                fewer_search_options: "Fewer Search Options",
                                delete_problem: "There was a problem deleting your search.",
                                layout: "Layout:",
                                feature_additional: "Choose an additional Feature?",
                                min_capacity_msg:
                                    "Minimum Capacity must be a number less than (or equal to) Maximum Capacity.",
                                choose_visible_columns: "Choose Visible Columns",
                                help_with_view: "Help for this view",
                                save_space_collection: "Save Location Search",
                                overlapping: "Overlapping",
                                category_loading: "Loading Categories...",
                                category: "Category:",
                                update_availability: "Update Availability",
                                max_capacity_msg:
                                    "Maximum Capacity must be a number greater than (or equal to) Minimum Capacity.",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                date_range: "Click to select a start and end date.",
                                remove_layout: "Remove this Layout",
                                select_date: "Click to select a date.",
                                turn_off_columns: "Turn individual columns on and off to customize the display.",
                                remove_feature: "Remove this Feature",
                                select_weekdays: "Confine your search results to selected days of the week.",
                                more_info: "More info?",
                                publisher:
                                    "Send events occurring in the locations displayed below to the 25Live Publisher.",
                                remove_category: "Remove this Category",
                                help_with_this_view: "Help for this view",
                            },
                        },
                        draft_conflicts: {
                            not_saved: "The event state was not changed.",
                            has_conflicts: "has conflicts on the following dates",
                            use_wizard:
                                "Please edit this draft via the Event Form to address the conflicts and update the event state.",
                            insufficient_stock: "has insufficient available stock on the following dates",
                            also_assign_explanation: "which must be also assigned when assigning",
                            header: "Draft Conflicts",
                            subdivision_of_explanation: "which is a subdivision of",
                            edit_draft: "Edit Draft within Event Form",
                            explanation:
                                "One or more locations and/or resources attached to this draft have conflicts with existing events.",
                        },
                        "s25-resource_search": {
                            text: {
                                date: "Date:",
                                category_additional_ellipsis: "Choose an additional Category...",
                                collection_saved_succesfully:
                                    "was successfully saved. You can view and run it by clicking Your Resource Searches.",
                                search_by_keyword: "Search by Keyword:",
                                category_all: "All Categories",
                                someone_elses: "You can only delete searches you created.",
                                sentence_nav_header: "Search for Resources",
                                retrieving_resources: "Retrieving Resources...",
                                more_search_options: "More Search Options",
                                button_save_search: "Save Search",
                                button_clear: "Clear",
                                select_today: "Select Today",
                                button_go: "GO",
                                too_short_search_term: "Search terms must be at least two characters long.",
                                same_day_view: "Same-Day Event View:",
                                dates: "Dates:",
                                no_resources: "There are no resources that meet the selected criteria.",
                                choose_collection_name: "Please choose a name for your search.",
                                list: "List",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                save_problem: "There was a problem saving your search.",
                                name: "Name",
                                category_additional: "Choose an additional Category?",
                                no_dates_text: "Date criteria cannot be saved for any searches in 25Live.",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                no_search_term: "You have not entered a search term.",
                                no_access_msg: "You have not selected any resources to view.",
                                matching_resources: "Matching Resources",
                                save_resource_collection: "Save Resource Search",
                                availability: "Availability",
                                rename_problem: "There was a problem renaming your search.",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                show_events_for_resource: "(Show Events for this Resource)",
                                or: "OR",
                                button_start_over: "START OVER",
                                refresh: "Refresh",
                                default_search_text: "Enter a Search Term...",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                stock_total: "Stock Total",
                                no_criteria_provided:
                                    "Please enter or select some criteria before starting your search.",
                                fewer_search_options: "Fewer Search Options",
                                choose_visible_columns: "Choose Visible Columns",
                                save_search: "Save this criteria as a Search:",
                                overlapping: "Overlapping",
                                category_loading: "Loading Categories...",
                                category: "Category:",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                select_date: "Click to select a date.",
                                help_with_view: "Help for this view",
                                columns: "Turn individual columns on and off to customize the display.",
                                more_info: "More info?",
                                remove_category: "Remove this Category",
                            },
                        },
                        "s25-related_events_copy": {
                            text: {
                                button_all_selected: "Copy All Selected Events",
                                footer_note:
                                    "<span><b>Note:</b> The new events will <b>not</b> be automatically related. You must establish event relationships on the final Event Summary page of the Event Form when you're done editing the copies.</span>",
                                button_only_current: "Copy Only This Event",
                                or_text: "or",
                                has_related_events: "has Related Events.",
                                header: "Copy Related Events",
                                copy_related_prompt: "Do you want to copy those as well?",
                            },
                            tooltips: {
                                help: "Help with Copying Related Events",
                            },
                        },
                        "s25-event_search": {
                            text: {
                                category_additional_ellipsis: "Choose an additional Category...",
                                collection_saved_succesfully:
                                    "was successfully saved. You can view and run it by clicking Your Event Searches.",
                                role_any: "Any Role / No Role",
                                organization_starting_with: "Select from Organizations starting with ",
                                state_additional_ellipsis: "Choose an additional State...",
                                search_by_keyword: "Search by Keyword:",
                                none: "- none -",
                                type: "Type",
                                category_all: "All Categories",
                                role_loading: "Loading Roles...",
                                sentence_nav_header: "Search For Events",
                                matching_events: "Matching Events",
                                cabinet_all: "All Cabinets",
                                organization_all: "All Organizations",
                                search_renamed: "was successfully renamed.",
                                organization_select: "Select an Organization",
                                state: "State",
                                no_collections: "No Searches",
                                no_recent_events: "No individual events have been recently viewed.",
                                cabinet_loading: "Loading Cabinets...",
                                more_search_options: "More Search Options",
                                button_clear: "Clear",
                                type_additional: "Choose an additional Type?",
                                button_go: "GO",
                                state_additional: "Choose an additional State?",
                                retrieving_events: "Retrieving Events...",
                                too_short_search_term: "Search terms must be at least two characters long.",
                                type_all: "All Types",
                                dates: "Dates:",
                                choose_collection_name: "Please choose a name for this search.",
                                loading: "Loading...",
                                organization_between: "Select from Organizations between ",
                                list: "List",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different name for your search.",
                                organization_group_loading: "Loading Organization Groups...",
                                save_problem: "There was a problem saving your search. Please try again later.",
                                category_additional: "Choose an additional Category?",
                                no_dates_text: "Date criteria cannot be saved for any searches in 25Live.",
                                actions: "Actions",
                                or_choose_from: "...or choose from",
                                no_search_term: "You have not entered a search term.",
                                no_access_msg: "You have not selected any events to view.",
                                role: "Your Role",
                                state_all: "All States",
                                columns: "Choose Visible Columns",
                                more_info: "More info?",
                                get_tickets: "Get Tickets",
                                organization_additional: "Choose an additional Organization?",
                                organization_name_range: "Select an Organization name range...",
                                save_event_collection: "Save Event Search",
                                calendar: "Calendar",
                                or: "OR",
                                state_loading: "Loading States...",
                                button_start_over: "START OVER",
                                refresh: "Refresh",
                                default_search_text: "Enter a Search Term...",
                                no_criteria_provided:
                                    "Please enter or select some criteria before starting your search.",
                                "pre-defined_searches": "Your Event Searches",
                                type_loading: "Loading Types...",
                                fewer_search_options: "Fewer Search Options",
                                delete_problem: "There was a problem deleting your search.",
                                category_loading: "Loading Categories...",
                                organization: "Organization",
                                organization_between_and: "and",
                                organization_loading: "Loading Organizations...",
                                category: "Category",
                                update_availability: "Update Availability",
                                cabinet: "Cabinet",
                                type_additional_ellipsis: "Choose an additional Type...",
                                attend_event: "Attend This Event",
                            },
                            tooltips: {
                                type_remove: "Remove this Type",
                                category_remove: "Remove this Category",
                                edit_event: "Edit this event.",
                                state_remove: "Remove this State",
                                columns: "Turn individual columns on and off to customize the display.",
                                publisher: "Send events displayed below to the 25Live Publisher.",
                                organization_remove: "Remove this Organization",
                                help_with_this_view: "Help for this view",
                            },
                        },
                        "s25-organization_search": {
                            text: {
                                category_additional_ellipsis: "Choose an additional Category...",
                                no_access_msg: "You have not selected any organizations to view.",
                                retrieving_organizations: "Retrieving Organizations...",
                                loading_events: "Loading Events...",
                                collection_saved_succesfully:
                                    "was successfully saved. You can view and run it by clicking Your Organization Searches.",
                                type_additional: "Choose an additional Type?",
                                type_all: "All Types",
                                choose_collection_name: "Please choose a name for your search.",
                                category_all: "All Categories",
                                type: "Type:",
                                type_loading: "Loading Types...",
                                save_organization_collection: "Save Organization Search",
                                sentence_nav_header: "Search For Organizations",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                no_organizations: "There are no organizations that meet the selected criteria.",
                                save_problem: "There was a problem saving your search. Please try again later.",
                                category_loading: "Loading Categories...",
                                matching_organizations: "Matching Organizations",
                                category_additional: "Choose an additional Category?",
                                category: "Category:",
                                type_additional_ellipsis: "Choose an additional Type...",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                remove_type: "Remove this Type",
                                remove_category: "Remove this Category",
                            },
                        },
                        "s25-awform_wiz": {
                            text: {
                                save_button: "Save",
                                view_form: "View Registration Form",
                                saving_form: "Saving Registration Form",
                            },
                        },
                        "s25-evdetails_email": {
                            text: {
                                share_secgroup:
                                    "Checking a box below will send an email to or share a search with all members of the selected group, which could potentially be a large number of users. If you're not sure how many users belong to a security group, check with your administrator.",
                                headcount: "Head Count",
                                no_matching_contacts: "No matching contacts.",
                                event_details_url: "Details URL",
                                subject: "Subject",
                                message_sent: "Message sent!",
                                bindings: "Bound Events",
                                sending_msg: "Sending Message...",
                                event_type: "Event Type",
                                message_body: "Message Body",
                                attach_file: "Attach:",
                                choose_contact_email: "Email addresses",
                                view_25live: "(View in 25Live)",
                                event_spaces: "Location(s) Assigned",
                                additional_recipients: "Additional Recipients",
                                add: "Add",
                                attendees: "Attendees",
                                event_title: "Title",
                                error_sending: "Error sending message. Please verify email addresses.",
                                send_button: "Send",
                                your_role: "Your Role",
                                close_button: "Close",
                                favorite_contacts: "Favorite Contacts",
                                event_name: "Name",
                                event_resources: "Resource(s) Assigned",
                                bcc: "BCC",
                                no_recipients: "You have not selected or entered any recipients.",
                                task_recipients: "Anyone with Notification Tasks",
                                event_registration_url: "Register for this event here",
                                anyone_with_assignments: "Anyone with Assignment Tasks",
                                scheduler: "Scheduler",
                                separate_with_commas:
                                    "Separate multiple email addresses with commas. To choose from a list, click To, CC or BCC.",
                                event_owner: "Event Owner",
                                event_details: "Event Details",
                                cc: "CC",
                                cancel_button: "Cancel",
                                event_state: "Event State",
                                related_recipients: "Related Recipients",
                                primary_org: "Primary Organization",
                                all_contacts: "All Contacts",
                                event_reference: "Reference",
                                profile_summary: "Summary",
                                repeats_on: "Repeats on",
                                requestor: "Requestor",
                                ical: "Ical File",
                                to: "TO",
                                event_details_instructions:
                                    "Check the checkbox to include Event Details. Add a custom message if desired.",
                                email_details: "Email Event Details",
                                other_orgs: "Additional Organization(s)",
                            },
                            tooltips: {
                                cancel: "Cancel",
                                remove_from_starred_contacts: "Remove this Contact from Your Starred Contacts",
                                help_with_email: "Help with emailing event details",
                                add_to_starred_contacts: "Add this Contact to Your Starred Contacts",
                                send: "Send",
                            },
                        },
                        "s25-resource_details": {
                            text: {
                                date: "Date",
                                instructions: "Default Instructions",
                                no_reservations: "There are no reservations for",
                                headcount: "Head Count",
                                none: "none",
                                availability_weekly: "Availability (Weekly)",
                                pre_event: "Pre-Event",
                                reference: "Reference",
                                post_event: "Post-Event",
                                default_view_calendar:
                                    'Make "Calendar" my default view when loading individual Resources',
                                fav: "Starred (Remove from Starred?)",
                                details: "Details",
                                starting_week: "Starting Week",
                                level: "Level",
                                create_event: "Create an Event that uses this Resource",
                                same_day_view: "Same-Day Event View:",
                                dates: "Dates:",
                                nonexistent_msg: "This resource does not exist, or has been deleted.",
                                loading: "Loading...",
                                list: "List",
                                takedown: "Takedown",
                                related_resources: "Related Resources",
                                default_view_list: 'Make "List" my default view when loading individual Resources',
                                number_of_weeks: "Number of Weeks",
                                showing_weekdays: "Weekdays:",
                                availability_daily: "Availability (Daily)",
                                guide_prompt: "What is this view?",
                                actions: "Actions...",
                                columns: "Choose Visible Columns",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                get_tickets: "Get Tickets",
                                availability: "Availability",
                                default_view_availability_weekly:
                                    'Make "Availability (Weekly)" my default view when loading individual Resources',
                                default_view_details:
                                    'Make "Details" my default view when loading individual Resources',
                                retrieving_info: "Retrieving Information...",
                                categories: "Categories",
                                close: "Close this Resource",
                                default_view_availability:
                                    'Make "Availability (Daily)" my default view when loading individual Resources',
                                start_date: "Start Date",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                comments: "Comments",
                                subscribe: "Subscribe to Events that use this Resource",
                                no_weekdays: "You must select at least one Weekday to display results.",
                                none_parens: "(none)",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                denial_msg: "You do not have permission to view this resource.",
                                occurring: "occurring",
                                buy_tix: "Buy Tickets",
                                overlapping: "Overlapping",
                                matching_reservations: "Matching Reservations",
                                organization: "Organization",
                                stock_totals: "Stock Totals",
                                show_events: "(Show Events for this Date)",
                                setup: "Setup",
                                attributes: "Attributes",
                                update_availability: "Update Availability",
                                event_time: "Event Times",
                                attend_event: "Attend This Event",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                select_number_of_weeks: "Select how many weeks to view.",
                                quantity: "Quantity:",
                                create_event: "Create an event using this resource on this date.",
                                columns: "Turn individual columns on and off to customize the display.",
                                select_starting_week: "Select the first week to view.",
                                last_page: "Last Page",
                                view_details: "Click to view resource details and check availability",
                                previous_page: "Previous Page",
                                close_view: "Close this view.",
                                title: "Title:",
                                sort_date: "Sort by Date",
                                takedown: "Takedown:",
                                reference: "Reference:",
                                next_page: "Next Page",
                                help_with_view: "Help for this view",
                                reserved: "Reserved:",
                                first_page: "First Page",
                                with_related: "With related resource",
                                organization: "Organization:",
                                select_weekdays: "Confine your search results to selected days of the week.",
                                reservation: "Reservation:",
                                setup: "Setup:",
                            },
                        },
                        "s25-evdetails_pricing": {
                            text: {
                                reason: "Reason",
                                bill_date: "Bill Date:",
                                edit_pricing: "Add an Adjustment",
                                save: "Save",
                                zero_org_error:
                                    "You can only subtract adjustments from organizations with a positive balance.",
                                taxes: "Taxes",
                                choose_group: "Choose a Rate Group...",
                                total: "Total",
                                percentage: "Percentage",
                                rate_schedule: "Rate Schedule",
                                rate_group: "Rate Group",
                                taxable_amount: "Taxable Amount",
                                choose_org: "Choose an organization...",
                                edit: "edit",
                                associated_event: "Associated Event:",
                                zero_list_error: "You can only subtract adjustments from line items with a list price.",
                                list_price: "List Price",
                                pricing_updated: "Your Adjustment was added.",
                                dollar_amt: "Dollar Amount",
                                choose_schedule: "Choose a rate schedule...",
                                save_problem: "There was a problem adding your Adjustment. Please try again later.",
                                organization: "Organization",
                                associated_item: "Line item:",
                                adjustment: "Adjustment",
                                zero_list_error_percentage:
                                    "You can only apply percentage adjustments to line items with a list price.",
                                event_locked: "This event is currently locked. Please try again later.",
                            },
                            tooltips: {
                                help_with_pricing: "Help with editing event pricing",
                            },
                        },
                        bx_daterangepicker: {
                            msgs: {
                                end_date_violation:
                                    "Your selected dates were modified because the selected End Date must be on or before the Latest End Date.",
                                unselectable_month:
                                    "This month could not be selected because the dates conflicted with the Earliest Allowed Start and/or the Latest Allowed End.",
                                unselectable_full_month:
                                    "The End Date was adjusted based on existing Date Range constrictions.",
                                start_date_violation:
                                    "Your selected dates were modified because the selected Start Date must be on or after the Earliest Start Date.",
                                date_range_restrictions:
                                    "Your selected dates were modified based on the existing Date Range constrictions.",
                            },
                        },
                        "s25-enroll": {
                            text: {
                                cancel_button: "Cancel/Close",
                                all: "All",
                                attend: "Attend?",
                                save_failed: "Error: Failed to save event",
                                success_msg: "You have successfully updated your attendance plans!",
                                no_iframes: "Your browser does not support iframes.",
                                none: "None",
                                loading_attendance: "Loading Attendance",
                                confirm_msgs: {
                                    multiple_new:
                                        "You are about to add ENROLL_COUNT new occurrences that you plan to attend.",
                                    one_new: "You are about to add 1 new occurrence that you plan to attend.",
                                    continue: "Continue?",
                                    also: "also",
                                    one_remove:
                                        "You are ALSO_TEXT about to remove 1 occurrence that you had planned to attend.",
                                    multiple_remove:
                                        "You are ALSO_TEXT about to remove UNENROLL_COUNT occurrences that you had planned to attend.",
                                },
                                register_to_attend: "Register to Attend:",
                                get_tix: "Get Tickets:",
                                saving: "Saving Attendance",
                                end_date_time: "End Date/Time",
                                submit_button: "Submit",
                                select_label: "Select",
                                cancelled: "Cancelled",
                                header: "Event Attendance",
                                reservation: "Reservation",
                                start_date_time: "Start Date/Time",
                                lock_msg: "This event is currently locked. Please try again later.",
                            },
                            tooltips: {
                                help_with_view: "Help for this view",
                            },
                        },
                        "s25-todo_create": {
                            text: {
                                date: "Due Date:",
                                missing_date: "You must enter a Due Date for your To Do Task.",
                                todo_name: "To Do Name:",
                                search_too_short: "Search Term must be at least two characters.",
                                choose_contact: "Choose a Contact below...",
                                todo_created: "Your To Do Task has been created.",
                                create: "Create",
                                todo_updated: "Your To Do Task has been updated.",
                                todo_comment: "To Do Comment:",
                                close: "Close",
                                edit_todo: "Edit To Do Task",
                                contacts_associated_with_event: "Contacts Associated with this Event...",
                                assigned_to: "Assigned To:",
                                missing_name: "You must enter a Name for your To Do Task.",
                                or: "OR",
                                edit: "Edit",
                                search_contacts: "Search for Contacts...",
                                associated_event: "Associated Event:",
                                loading: "Loading...",
                                select_contact: "Choose from Search Results...",
                                associated_contacts: "Choose from Associated Contacts...",
                                create_another: "Create Another To Do Task",
                                save_problem:
                                    "There was a problem with saving your To Do Task. Please try again later.",
                                success: "Success!",
                                assigned_by: "Assigned By:",
                                starred_contacts: "Starred Contacts...",
                                create_todo: "Create To Do Task",
                                no_contacts: "No matching contacts...",
                                event_locked: "The associated event is currently locked. Please try again later.",
                                missing_contact: "You must select a Contact to whom you will assign your To Do Task.",
                            },
                            tooltips: {
                                help_with_todo: "Help with creating a To Do",
                                close: "Close",
                            },
                        },
                        ecommerce: {
                            text: {
                                option_occurrence: "Occurrence: ",
                                have_form_code:
                                    "Click here if you have a CollegeNET ApplyWeb or Contribute.com Form Code.",
                                fees: {
                                    cancel_button: "Cancel",
                                    end_date: "Ends",
                                    fees: "Fees & Discounts",
                                    move: "Click here and drag to change the order this fee is applied in",
                                    add_fee: "Add Other Fee/Tax",
                                    edit_service_fee_button: "Edit Service Fee Details",
                                    fee_adj_title: "Fee",
                                    description: "Name",
                                    active: "Active?",
                                    promo_code: "Promo Code",
                                    add_service_fee: "Add Service Fee",
                                    type: "Type",
                                    service_fee_adj_title: "Service Fee",
                                    save_button: "Save",
                                    edit_discount_button: "Edit Discount Details",
                                    effect: "Fixed or Percent",
                                    discount_adj_title: "Discount",
                                    edit_fee_button: "Edit Fee Details",
                                    save_fees: "Save All Modified",
                                    add_discount: "Add Discount",
                                    value: "Amount",
                                    start_date: "Starts",
                                },
                                option_select: "Select...",
                                refresh: "Refresh",
                                not_available: {
                                    userFormCode:
                                        '<span>This event has a user-supplied form code of <b><span c:id="formCode"/></b>. Please contact your system administrator to manage this form.</span>',
                                    draft: "<span>This event is a <b>Draft</b>. E-Commerce can only be enabled for the <b>Confirmed</b> and <b>Tentative</b> event states.</span>",
                                    ols: "<span>You do not have rights to manage E-Commerce data for this event.</span>",
                                    header: "E-Commerce is Not Available for this Event",
                                    state: '<span>This event has an event state of <b><span c:id="state"/>"</b>. E-Commerce can only be enabled for the following event states: <b>Confirmed</b> and <b>Tentative</b>.</span>',
                                    type: '<span>This event has an event type of <b><span c:id="type"/>"</b>. E-Commerce cannot be enabled because the required custom attributes have not been assigned to this event type.</span>',
                                },
                                initializing_ecommerce: "Initializing E-Commerce...",
                                transactions: {
                                    loading_supply_group: "Loading Supply Group...",
                                    loading_transactions: "Loading Transactions...",
                                    address: "Address",
                                    gender: "Gender",
                                    birth_date: "DOB",
                                    totals: "Totals",
                                    transactions: "Transactions",
                                    occurrence_supply_group: "Occurrence Supply Group",
                                    total: "Total",
                                    show_transactions: "Show Transactions",
                                    no_associated_transactions: "There are no associated transactions.",
                                    orphan_occurrences:
                                        "The following transactions are not associated with any occurrence.",
                                    move_occurrence: "Move to Current Occurrence",
                                    event_supply_group: "Event Supply Group",
                                    phone: "Phone",
                                    sale_date: "Sale Date",
                                    warning_no_sales_form:
                                        "There are no sales or registrants associated with this event; the event does not have any supply groups.",
                                    orphan_occurrences_instructions:
                                        "Click the button below to move the corresponding supply group to the current occurrence.",
                                    of: " of ",
                                    warning_no_supply_groups:
                                        "There are no sales or registrants associated with this event; the event does not have any supply groups.",
                                    supply_group: "Supply Group",
                                    scope_preface:
                                        "View transaction reports for the entire event or individual occurrences.",
                                },
                                option_event: "Event",
                                set_up_ecommerce: "Set Up E-Commerce",
                                form: {
                                    banner_image_instructions:
                                        "This image is displayed at the top of the form. The value should be an HTTPS-protected URL to avoid browser warnings.",
                                    availability_end_date_instructions:
                                        "The last date when the form accepts registration/order submissions.",
                                    revenue_split_by_accounting_code: "Revenue Split by Accounting Code",
                                    merchant_accept_amex: "Amex",
                                    sale_description: "Sale Description",
                                    email_sender_address: "Email Sender Address",
                                    add_accounting_code: "Add an Accounting Code",
                                    event_date_description: "Event Date Description",
                                    banner_image: "Banner Image",
                                    revenue_split_instructions:
                                        "Add the accounting code and percentage revenue share of each sponsor organization. Each accounting code has a maximum length of 12 characters. The total combined length of all accounting codes cannot exceed 50 characters.",
                                    date_description:
                                        "Be sure to review for clarity the system-provided event date description, which is added automatically to confirmation email.",
                                    sale_description_instructions: "Simple HTML is permitted.",
                                    merchant_and_sales_data: "Merchant and Sales Data",
                                    create_form: "Create Form",
                                    link_label: "Link Label",
                                    update_form: "Update Form",
                                    receipt: "Receipt",
                                    form_display: "Form Display",
                                    availability_start_date_instructions:
                                        "The date when the form becomes available to accept registration/order submissions.",
                                    form_builder: "Form Builder",
                                    email_subject: "Email Subject",
                                    merchant_phone_note: "(xxx-xxx-xxxx format only)",
                                    gender: "Gender",
                                    date_of_birth: "Date of Birth",
                                    link: "Link",
                                    contact_information: "Contact Information",
                                    get_tickets: "Get Tickets",
                                    merchant_phone: "Merchant Phone",
                                    percent: "Percent",
                                    powered_by_formzilla: "Powered by Formzilla",
                                    merchant_state_note: "(2 letter state abbreviation)",
                                    email: "Email",
                                    merchant_accept: "Accepted Cards",
                                    merchant_description_instructions_important:
                                        "It is very important that this value reflect your institution.",
                                    address: "Address",
                                    yes: "Yes",
                                    ecommerce_link_label: "E-Commerce Link Label",
                                    message: "Message",
                                    form_title: "Form Title",
                                    accounting_code: "Accounting Code",
                                    email_body: "Email Body",
                                    contact_information_instructions:
                                        "Select any of these fields to add them to the registration/order form. When selected, the fields are required. (A phone number is always required.)",
                                    availability_start_date: "Availability Start Date",
                                    merchant_state: "Merchant State",
                                    link_instructions:
                                        "Enter a URL to make available from the confirmation page for the E-Commerce registration/order form; for example, a link to your institution's home page.",
                                    ecommerce_link_label_instructions:
                                        "The text to accompany the event name in a calendar entry, that invites viewers to click to register or place an order.",
                                    merchant_description_instructions:
                                        "The Merchant Description will appear on the customer's credit card statement to identify the vendor for the E-Commerce purchase. Enter a maximum of 20 alphanumeric characters.",
                                    saving_form: "Saving Form...",
                                    availability_end_date: "Availability End Date",
                                    merchant_description: "Merchant Description",
                                    email_sender_name: "Email Sender Name",
                                },
                                supplies: {
                                    cancel: "Cancel",
                                    stock_quantity_alert: "Stock quantity may not be greater than 20000.",
                                    available: "available",
                                    date_conflict_alert:
                                        "The Availability End Date cannot be earlier than the Availability Start Date.",
                                    purchase_limit: "Purchase Limit",
                                    rsvp: "RSVP?",
                                    creating_display_group: "Creating Display Group...",
                                    availability_start: "Availability Start",
                                    supplies: "Supplies",
                                    price: "Price",
                                    time_conflict_alert:
                                        "The Availability End Time cannot be earlier than the Availability Start Time.",
                                    supply_group: "Supply Group",
                                    supplies_for_event: "Supplies for Event",
                                    new_supply_item: "New Supply Item",
                                    loading_supplies: "Loading Supplies...",
                                    test: "Testing the loading of Lang",
                                    group_name: "Group Name",
                                    updating_supply_group: "Updating Supply Group...",
                                    initial_stock_quantity: "Initial Stock Quantity",
                                    supplies_for_occurrence: "Supplies for Occurrence",
                                    active: "Active?",
                                    transaction_type: "Transaction Type",
                                    creating_supply_group: "Creating Supply Group...",
                                    edit_item_details: "Edit Item Details",
                                    initial_stock_quantity_info:
                                        "Specify the maximum quantity of any items you plan to offer for the group (the online help for this view provides examples). The initial stock quantity is the expected head count for the event (or 1 if event details do not specify an expected head count). You can change the stock quantity later (to a value greater than what's been ordered).",
                                    supply_group_no_items: "This supply group does not have any items.",
                                    no: "No",
                                    inventory_creation_failure_alert: "Warning: Failed to create inventory.",
                                    group_name_info:
                                        "The group name appears on the order form. Be descriptive. You can change the name later.",
                                    new_supply_group: "New Supply Group",
                                    save: "Save",
                                    description: "Description",
                                    inventory_stock_alert:
                                        "The new inventory stock quantity may not be less than the currently available stock count.",
                                    stock_available: "Stock Available",
                                    inventory_status: "Inventory Status",
                                    edit_group_details: "Edit Group Details",
                                    updating_resources: "Updating Resources...",
                                    no_associated_supply_groups: "There are no associated supply groups.",
                                    inventory_update_failure_alert: "Warning: Failed to update inventory.",
                                    availability_end: "Availability End",
                                    items_sold: "sold",
                                    sold: "Sold",
                                    yes: "Yes",
                                    item_quantity_warning:
                                        "This item's stock count exceeds the group's stock count. Please adjust the group stock count accordingly.",
                                    label: "Label",
                                    stock_quantity: "Stock Quantity",
                                    description_note: "250 character limit. No HTML allowed.",
                                    create_supply_group: "Create Supply Group",
                                    display_group_creation_failure_alert: "Warning: Failed to create display group.",
                                    in_process: "In Process",
                                    rsvp_info:
                                        "Items in an RSVP Supply Group must have zero dollar amounts. Once created as an RSVP Supply Group, the Supply Group cannot be switched to a non-RSVP Supply Group.",
                                    group_id_failure_alert: "Error: Failed to get display group ID for inventory",
                                    add_supply_group: "Add Supply Group",
                                    add_new_item: "Add New Item",
                                    scope_preface: "Manage supplies for the entire event or individual occurrences.",
                                },
                                last_update: "Information current as of ",
                                form_code: {
                                    saving_form_code: "Saving Form Code...",
                                    cancel: "Cancel",
                                    preface:
                                        "If you have a code for an existing form that was created for you by a CollegeNET ApplyWeb or Contribute.com forms developer and you want to associate that form with this event, enter the code here.",
                                    note: "NOTE: You cannot undo this action.",
                                    link_label_default: "Register Here",
                                    save: "Save My Form Code",
                                    update: "Update",
                                    link_label: "E-Commerce Link Label",
                                    form_code: "Form Code",
                                },
                                email: {
                                    group_title: "",
                                    add: "Add",
                                    cancel_button: "Cancel",
                                    send_success_message: "Email sent successfully.",
                                    all_title: "",
                                    subject_label: "Subject:",
                                    zero_to_message: "No addresses found.",
                                    to_label: "To:",
                                    uncheck_all: "Uncheck All",
                                    check_all: "Check All",
                                    save_button: "Save",
                                    send_fail_message: "Sorry! There was an error sending the email.",
                                    group_post_text: "Send email to all customers/registrants for this supply group",
                                    done_button: "Return to email",
                                    multi_to_message: "Click here to see list of recipients.",
                                    single_to_message: "Click here to see recipient.",
                                    all_post_text:
                                        "Send email to all customers/registrants for all supply group transactions currently showing",
                                    send_none_message: "No addresses selected. Not sending email.",
                                    body_label: "Body:",
                                    from_label: "From:",
                                },
                            },
                            tooltips: "",
                        },
                        bx_help: {
                            text: {
                                help: "Help",
                                help_title: "25Live Help",
                                standalone: "Open Help in standalone browser window.",
                                window_label: "25Live Help",
                            },
                            tooltips: {
                                click_for_help: "Click for 25Live Help!",
                            },
                        },
                        controller: {
                            text: {
                                fail_alerts: {
                                    cannot_edit_cancelled_event:
                                        "You do not have permission to edit events that have been Cancelled.",
                                    cannot_edit_nondraft_event: "You only have permission to edit Draft events.",
                                    no_scheduling_permissions: "Sorry, you are not allowed to schedule events.",
                                    cannot_edit_sealed_event:
                                        "You do not have permission to edit events that have been Sealed.",
                                    ownership_fail:
                                        "Sorry, you were not set to the owner of this event. Please try again later.",
                                    no_editing_permissions: "Sorry, you do not have permission to edit this event.",
                                    cannot_edit_draft_event: "You do not have permission to edit Draft events.",
                                    no_multi_reservation_cloning:
                                        "Sorry, this event has multiple reservations and cannot be copied in 25Live.",
                                    cannot_edit_denied_event:
                                        "You do not have permission to edit events that have been Denied.",
                                    cannot_edit_confirmed_event:
                                        "You do not have permission to edit events that have been Confirmed.",
                                    no_cloning_permissions: "Sorry, you do not have permission to copy this event.",
                                    event_sis:
                                        "This event originated in your SIS and is in locked transactional mode, so it cannot be edited in 25Live. See documentation for information on locked transactional events.",
                                    organization_denied:
                                        "This Organization is not currently allowed to schedule events. Contact your System Administrator for more information.",
                                    cannot_edit_tentative_event: "You do not have permission to edit Tentative events.",
                                    event_locked: "This event is currently locked. Please try again later.",
                                },
                                today_is: "Today is",
                                loading: "Loading",
                                success_alerts: {
                                    ownership_success: "You are now the owner of this event.",
                                },
                                state_sealed: "Sealed",
                                multiple_outstanding_tasks_alert: {
                                    part_5: "and require your attention.",
                                    part_4: "Overdue",
                                    part_3: "that are",
                                    part_2: "Outstanding Tasks",
                                    part_1: "You have",
                                },
                                state_draft: "Draft",
                                state_confirmed: "Confirmed",
                                state_tentative: "Tentative",
                                one_outstanding_task_alert: {
                                    part_5: "and requires your attention.",
                                    part_4: "Overdue",
                                    part_3: "that is",
                                    part_2: "Outstanding Task",
                                    part_1: "You have an",
                                },
                                state_denied: "Denied",
                                invalid_event_locator: "There are no events that match that Event Locator.",
                                state_cancelled: "Cancelled",
                                view_overdue_tasks: "Click to View Your Overdue Tasks",
                            },
                        },
                        layout: {
                            text: {
                                next: "Next",
                                cancel: "Cancel",
                                location_tab: "Locations",
                                delete_search: "Delete Search",
                                no_locations: "There are no locations that meet the selected criteria.",
                                footer: {
                                    feedback: "Feedback",
                                    help_tips: "Help Tips",
                                    text_size: "Text Size",
                                    disabled: "Disabled",
                                    enabled: "Enabled",
                                    icon_legend: "Icon Legend",
                                },
                                grid_resize_note: "Click and Drag dashed bottom border to resize Availability grid.",
                                task_tab: "Tasks",
                                get_tickets: "Get Tickets",
                                search_name_blank: "You must enter a valid search name.",
                                organization_tab: "Organizations",
                                publisher_tab: "Publisher",
                                reports_tab: "Reports",
                                confirm_delete: "Confirm Search Deletion",
                                select_space_to_view: "Select a space to view its details.",
                                event_tab: "Events",
                                no_organizations: "There are no organizations that meet the selected criteria.",
                                no_events: "No events...",
                                of: "of",
                                welcome: "Welcome,",
                                user_preferences: "Preferences",
                                no_recent_locations: "No individual locations have been recently viewed.",
                                last: "Last",
                                previous: "Previous",
                                invalid_login_msg: "Invalid user name or password.",
                                no_resources: "There are no resources that meet the selected criteria.",
                                loading: {
                                    event_browse: "Loading Quick Event Searches...",
                                    todo_calendar: "Loading To Do Calendar...",
                                    space_browse: "Loading Quick Location Searches...",
                                    home_calendar: "Loading Home Calendar...",
                                    organization_reports: "Loading Organization Reports...",
                                    tasks_overview: "Loading Overview of Tasks...",
                                    starred_reports: "Loading Event Reports...",
                                    task_search: "Loading Task Search",
                                    event_wizard: "Loading Event Form...",
                                    resource_search_advanced: "Loading Advanced Resource Search...",
                                    organization_browse: "Loading Quick Organization Searches...",
                                    resource_browse: "Loading Quick Resource Searches...",
                                    organization_search_advanced: "Loading Advanced Organization Search...",
                                    initial: "Loading 25Live...",
                                    space_search: "Loading Location Search...",
                                    home_dashboard: "Loading Home Dashboard...",
                                    other_reports: "Loading Reports...",
                                    space_search_advanced: "Loading Advanced Location Search...",
                                    tasks_agenda: "Loading Tasks Agenda...",
                                    loading: "Loading...",
                                    event_search_advanced: "Loading Advanced Event Search...",
                                    resource_search: "Loading Resource Search...",
                                    space_reports: "Loading Location Reports...",
                                    event_reports: "Loading Event Reports...",
                                    organization_search: "Loading Organization Search...",
                                    event_search: "Loading Event Search...",
                                    resource_reports: "Loading Resource Reports...",
                                },
                                no_events_match: "There are no events that meet the selected criteria.",
                                confirm_vcal_instructions:
                                    "This event has incomplete vCalendar To Do tasks. To send a vCalendar Reply/Counter for this event anyway, click the Send Reply/Counter button.",
                                confirm_delete_instructions: "Are you sure you want to delete this search?",
                                confirm_vcal: "Confirm vCal Reply/Counter",
                                save_search: "Save",
                                wizard_tab: "Event Form",
                                send_vcal: "Send Reply/Counter",
                                page: "Page",
                                print_background_tip:
                                    '<b>Things don\'t look like you expect?</b> You might need to set your browser to print background colors and images. In <b>Firefox</b>: Click <b>Page Setup...</b>, then check the box beside "<b>Print Background (colors & images)</b>". In <b>Internet Explorer</b>: Exit Print Preview. From the browser menu, select <b>Tools</b> > <b>Internet Options</b>. Select the "<b>Advanced</b>" tab. In the <b>Settings</b> box, scroll to the "<b>Printing</b>" section, then check the box beside "<b>Print background colors and images</b>". <b>Note:</b> Consider also printing in Landscape format instead of Portrait. ',
                                first: "First",
                                no_recent_resources: "No individual resources have been recently viewed.",
                                attend_event: "Attend This Event",
                                resource_tab: "Resources",
                                home_tab: "Home",
                            },
                            tooltips: {
                                location_tab:
                                    "View room and other location activity and characteristics. Start creating an event from a selected location. Create and save your own location searches.",
                                to_starred_locations: "to Your Starred Locations",
                                to_starred_event_searches: "to Your Starred Event Searches",
                                reference: "Reference:",
                                schedule_event: "Schedule an event using",
                                to_starred_location_searches: "to Your Starred Location Searches",
                                event_tab: "View events important to you. Create and save your own event searches.",
                                from_starred_resource_searches: "from Your Starred Resource Searches",
                                text_size_reset: "Reset the Text Size",
                                reservation: "Reservation:",
                                sort_by_name: "Sort by Name",
                                add: "Add",
                                from_starred_event_searches: "from Your Starred Event Searches",
                                from_starred_organizations: "from Your Starred Organizations",
                                takedown: "Takedown:",
                                resource_utilization: "Resource Utilization:",
                                reserved: "Reserved:",
                                wizard_tab:
                                    "Create new events, and access the events you're currently creating or editing.",
                                event_name: "Short Name:",
                                schedule_event_in: "Schedule an event in",
                                from_starred_location_searches: "from Your Starred Location Searches",
                                sort_by_stock: "Sort by Stock Total",
                                home_tab: "Gain quick access to key information and functionality.",
                                task_tab: "View, and respond to, tasks assigned to you or by you.",
                                more_info: "More Info?",
                                sort_by_formal_name: "Sort by Formal Name",
                                previous_page: "Previous Page",
                                reports_tab: "Run and print reports, and email them to yourself and others.",
                                publisher_tab: "Review and manage data sent to the 25Live Publisher.",
                                title: "Title:",
                                from_starred_organization_searches: "from Your Starred Organization Searches",
                                remove: "Remove",
                                next_page: "Next Page",
                                to_starred_organization_searches: "to Your Starred Organization Searches",
                                text_size_increase: "Increase the Text Size",
                                first_page: "First Page",
                                in_related_location: "In related location",
                                to_starred_resources: "to Your Starred Resources",
                                from_starred_events: "from Your Starred Events",
                                quantity: "Quantity:",
                                edit_event: "Edit this event.",
                                last_page: "Last Page",
                                to_starred_organizations: "to Your Starred Organizations",
                                to_starred_events: "to Your Starred Events",
                                from_starred_locations: "from Your Starred Locations",
                                from_starred_resources: "from Your Starred Resources",
                                text_size_decrease: "Decrease the Text Size",
                                organization: "Organization:",
                                setup: "Setup:",
                                event_time: "Event Time:",
                                create_event_in: "Create an event in",
                                resource_tab:
                                    "View resource assignments and characteristics. Reserve selected resources. Create and save your own resource searches.",
                                to_starred_resource_searches: "to Your Starred Resource Searches",
                            },
                        },
                        "s25-publisher": {
                            text: {
                                please_sign_in: "Please Sign In to 25Live Publisher",
                                already_being_sent:
                                    '"FEED_NAME" is already being sent to the "CALENDAR_NAME" calendar.',
                                signed_in_as: "signed in as",
                                reservations_in_event_collection: "reservations in event search",
                                create_new_calendar: "Create a new 25Live Publisher calendar for these events.",
                                publisher_password: "25Live Publisher Password",
                                sign_in_button: "Sign In",
                                show_setup_takedown: "Include setup and takedown times.",
                                send_to_existing_calendar:
                                    'Send FEED_TYPE "FEED_NAME" to an existing 25Live Publisher calendar.',
                                none: "none",
                                create_as_subcalendar: "Create as a sub-calendar of",
                                only_with_spaces: "Publish only event occurrences with location assignments.",
                                include_rsrv_comments: "Include reservation comments.",
                                is_already_being_sent: "is already being sent to the following calendars:",
                                updating_calendar: "Updating Calendar",
                                creating_calendar: "Creating Calendar",
                                use_event_title: "Show event title (rather than event name).",
                                send_to_publisher: "Send Events to 25Live Publisher",
                                sign_out: "sign out",
                                create_and_send: "Create Calendar and Send Events",
                                publisher_username: "25Live Publisher Username",
                                calendar_already_exists: 'A calendar with the name "CALENDAR_NAME" already exists.',
                                no_calendars_available: "No Calendars Available",
                                reservations_for_event: "reservations for event",
                                events_in_location_collection: "events in location search",
                                stop_sending_events:
                                    'Stop sending events from "FEED_NAME" to the "CALENDAR_NAME" calendar?',
                                events_in_location: "events in location",
                                feed_options: "Feed Options",
                                space_feed_options: "Space Feed Options",
                                sign_in_failure: "Sorry, failed to sign in to 25Live Publisher. Please try again.",
                                loading_calendars: "Loading 25Live Publisher Calendars",
                                close_button: "Close",
                                removing_feed: "Removing Feed",
                                show_formal_name: "Show location formal name (rather than short name).",
                                send_to_calendar: "Send Events to Existing Calendar",
                                publisher: "25Live Publisher",
                                enter_a_name: "Enter a name for your new 25Live Publisher calendar:",
                                open: "Open",
                                status: "Status",
                            },
                            tooltips: {
                                stop_sending_events: "Click to stop sending these events to this calendar.",
                                help_with_publishing: "Help with publishing",
                                open_in_new_window: "Open 25Live Publisher in new window",
                            },
                        },
                        "s25-task_agenda": {
                            text: {
                                setup_before: "Setup before",
                                legend: "Legend:",
                                view_ical_feed: "View iCal Feed",
                                subscribe_to_agenda: "Subscribe to Tasks Agenda",
                                more_info: "More info?",
                                expand_all: "Expand All",
                                takedown_duration: "Takedown duration:",
                                denied_declined: "Denied/Declined",
                                active_pending: "Active/Pending",
                                subscribe_instructions: "Subscribe with a web-based calendar:",
                                setup_after: "Setup after",
                                my_tasks: "My tasks...",
                                refresh: "Refresh",
                                tasks_agenda: "Tasks Agenda",
                                dates: "Dates:",
                                update_agenda: "Update Agenda",
                                viewing_tasks: "Viewing Tasks:",
                                collapse_all: "Collapse All",
                                setup_duration: "Setup duration:",
                                ical: "Subscribe to your Agenda via iCal",
                                loading_tasks_agenda: "Loading Tasks Agenda...",
                                approved_completed: "Approved/Completed",
                                cancelled: "Cancelled",
                                actions: "Actions... ",
                                takedown_after: "Takedown after",
                            },
                            tooltips: {
                                show_tasks: "Show these tasks",
                                help_with_view: "Help for this view",
                                view_only_this_day: "View only this day's Tasks",
                                hide_tasks: "Hide these tasks",
                            },
                        },
                        c25config: {
                            auth: {
                                today_is: "Today is",
                                welcome: "Welcome",
                            },
                            text: {
                                nav_prefs_resource_options: "Resource Options",
                                content_prefs_show: "Show",
                                "desc_navhint-default": "<div>Select a section on your left to begin</div>",
                                nav_LML_space_cust_atrb: "Location Custom Attributes",
                                "desc_navhint-prefs":
                                    "<div>Click the eye to show/hide elements.</div><div>Set names as they should appear.</div>",
                                "desc_navhint-LML-evt_cust_atrb_special":
                                    "<div>Select the section in which to display the Event Custom Attribute.</div>",
                                nav_prefs_whats_happening: "What's Happening",
                                nav_prefs_event_options: "Event Options",
                                nav_LML_event_type: "Event Types",
                                nav_LML_event_cat: "Event Categories",
                                nav_LML_resource_cat: "Resource Categories",
                                content_LML_ev_cust_atrb_selection_both: "both",
                                nav_LML_event_cust_atrb: "Event Custom Attributes",
                                nav_LML_event_role: "Event Contact Roles",
                                content_prefs_privileged: "hidden from not-logged-in users (privileged)",
                                content_LML_ev_cust_atrb_selection_request: "request",
                                content_prefs_hide: "Hide",
                                nav_LML_space_layout: "Location Layouts",
                                content_LML_available: "Available for Selection",
                                "desc_navhint-LML":
                                    "<div>Select the %LML_name% to appear by default in 25Live.</div><div>Drag selected elements to set their order.</div>",
                                nav_LML_space_feat: "Location Features",
                                content_LML_enabled: "Selected",
                                tab_LML: "Local Master Lists",
                                content_prefs_apply: "Apply",
                                content_LML_ev_cust_atrb_selection_detail: "detail",
                                content_prefs_name: "name",
                                content_LML_save_button: "Save",
                                nav_LML_resource_cust_atrb: "Resource Custom Attributes",
                                print_button: "Print",
                                tab_prefs: "Preferences",
                                nav_LML_space_cat: "Location Categories",
                                nav_prefs_space_options: "Location Options",
                            },
                            tooltips: {
                                disable_button: "Remove %item% from 25Live",
                                enable_button: "Add %item% to 25Live",
                            },
                        },
                        "s25-resource_search_advanced": {
                            text: {
                                date: "Date:",
                                search_groupings: "Search Groupings",
                                hide_search_criteria: "Hide",
                                index_keyword: "Index",
                                someone_elses: "You can only delete searches you created.",
                                retrieving_resources: "Retrieving Resources...",
                                search_renamed: "was successfully renamed.",
                                open_resources: "Individual Resources You Have Open",
                                fav: "Starred (Remove from Starred?)",
                                no_collections: "No Searches",
                                rename_someone_elses: "You can only rename searches you created.",
                                select_today: "Select Today",
                                restart_link: "(Show Events for this Resource)",
                                rename_search: "Rename Search",
                                no_search_selected: "You have not yet selected a Resource Search.",
                                same_day_view: "Same-Day Event View:",
                                no_resource_categories: "No Resource Categories",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                edit_resource_collection: "Rename Resource Search",
                                no_resources: "There are no resources that meet the selected criteria.",
                                choose_collection_name: "Please choose a name for your search.",
                                list: "List",
                                index_all_collection: "All of Your Searches",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                save_problem: "There was a problem saving your search. Please try again later.",
                                name: "Name",
                                guide_prompt: "What is this view?",
                                actions: "Actions",
                                advanced_search_header: "Advanced Resource Search",
                                browse_instructions:
                                    'Select a Search Grouping (left) to browse existing searches, or click the "New Search" button.',
                                delete_search: "Delete Search",
                                no_access_msg: "You have not selected any resources to view.",
                                starred_resources: "Your Starred Resources",
                                columns: "Choose Visible Columns",
                                matching_resources: "Matching Resources",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                availability: "Availability",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                choose_search_grouping:
                                    "Choose a Search Grouping on the left, then select a Search from the resulting list on the right.",
                                popular_resources: "Popular Resources",
                                categories: "Categories",
                                calendar: "Calendar",
                                loading_events: "Loading Events...",
                                stopped: "Stopped",
                                index_collection: "Your Starred Searches",
                                show_events_for_resource: "(Show Events for this Resource)",
                                refresh: "Refresh",
                                preparing_to_retrieve: "Preparing to retrieve events...",
                                separated: "Separated",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                index_default_collection: "Public Searches",
                                viewing_results_for: "You are viewing results for",
                                index_category: "Categories",
                                overlapping: "Overlapping",
                                custom_groups: "Pre-Defined Groups",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: {
                                next_page: "Next Page",
                                add: "Add",
                                click_select_date: "Click to select a date.",
                                help_with_view: "Help for this view",
                                last_page: "Last Page",
                                first_page: "First Page",
                                columns: "Turn individual columns on and off to customize the display.",
                                sort_by_name: "Sort by Name",
                                previous_page: "Previous Page",
                                title: "Title:",
                                remove: "Remove",
                            },
                        },
                        "s25-organization_browse": {
                            text: {
                                no_access_msg: "You have not selected any organizations to view.",
                                index_collection: "Your Starred Searches",
                                retrieving_organizations: "Retrieving Organizations...",
                                loading_events: "Loading Events...",
                                rename_someone_elses: "You can only rename searches you created.",
                                index_keyword: "Index",
                                open_organizations: "Individual Organizations You Have Open",
                                browse_header: "Pre-Defined Organization Searches",
                                search_deleted: "was successfully deleted.",
                                delete_problem: "There was a problem deleting your search.",
                                index_type: "Types",
                                someone_elses: "You can only delete searches you created.",
                                index_default_collection: "Public Searches",
                                index_all_collection: "All of Your Searches",
                                index_category: "Categories",
                                suggested_organizations: "Suggested Organizations",
                                no_organizations: "There are no organizations that meet the selected criteria.",
                                matching_organizations: "Matching Organizations",
                                custom_groups: "Pre-Defined Groups",
                                no_recent_organizations: "No individual organizations have been recently viewed.",
                                starred_organizations: "Your Starred Organizations",
                                no_collections: "No Searches",
                                retrieving_events_for: "Retrieving events for:",
                            },
                            tooltips: "",
                        },
                        application: {
                            text: {
                                availability_weekly_guide: {
                                    button: "Hide this Guide",
                                    caption_reserve_resource:
                                        "Click an empty slot below to start creating an event using that resource at that time.",
                                    description_location:
                                        "<p>The <b>Weekly Availability View</b> allows for the comparison of events scheduled in the current location over multiple weeks, grouped by day. It also shows any other restrictions on location availability, such as closing times. An empty time slot means the location is available at that time.</p>",
                                    header: "What is the Weekly Availability view?",
                                    for_more: "For more on this Availability view,",
                                    description_resource:
                                        "<p>The <b>Weekly Availability View</b> allows for the comparison of events using the current resource over multiple weeks, grouped by day. An empty slot means the resource is available at that time.</p>",
                                    click_here: "click here",
                                    caption_reserve_location:
                                        "Click an empty slot below to start creating an event in that location at that time.",
                                    caption_hover:
                                        "Hover over an event to see a brief summary, or click the event color bar to load full details.",
                                },
                                subscriptions: {
                                    ical_note:
                                        "Note: By default, Microsoft Outlook may create a new calendar instead of adding these events to your primary calendar. To avoid this, use Outlook's File > Import option instead.",
                                    view_atom_feed: "View Atom feed",
                                    subscribe_reader: "Subscribe with a web-based news reader:",
                                    subscribe_calendar: "Subscribe with a web-based calendar:",
                                    subscription_options: "Subscription Options",
                                    view_ical_feed: "View iCal feed",
                                },
                                columns: {
                                    setup_time: "Setup",
                                    headcount: "Head Count",
                                    type: "Type",
                                    title: "Title",
                                    layouts: "Layouts",
                                    task_item: "Task Item",
                                    custom_attributes: "Custom Attributes",
                                    scheduler: "Scheduler",
                                    reference: "Reference",
                                    features: "Features",
                                    state: "State",
                                    categories: "Categories",
                                    formal_name: "Formal Name",
                                    assigned_to: "Assigned To",
                                    start_date: "Start Date",
                                    max_capacity: "Max Capacity",
                                    resources: "Resources",
                                    stock_total: "Stock Total",
                                    creation_date: "Creation Date",
                                    takedown_time: "Takedown",
                                    your_role: "Your Role",
                                    post_time: "Post-Event",
                                    accounting_code: "Accounting Code",
                                    start_time: "Start Time",
                                    name: "Name",
                                    organizations: "Organizations",
                                    event_name: "Event",
                                    locations: "Locations",
                                    assigned_by: "Assigned By",
                                    pre_time: "Pre-Event",
                                    respond_by: "Respond By",
                                    actions: "Actions",
                                    first_date: "First Date",
                                    event_time: "Event Times",
                                    status: "Status",
                                },
                                home_collections: {
                                    collection_starred_resources_viewer: "Events using Popular Resources",
                                    collection_hot: "Hot Events",
                                    collection_starred_organizations: "Events in Your Starred Organizations",
                                    collection_scheduled: "Events You Are Scheduling",
                                    collection_starred_events_visitor: "Featured Events",
                                    collection_starred_spaces: "Events in Your Starred Locations",
                                    collection_tasks: "Events with Tasks Assigned to You",
                                    collection_starred_spaces_viewer: "Events in Popular Locations",
                                    collection_starred_organizations_visitor: "Events in Suggested Organizations",
                                    collection_starred_organizations_viewer: "Events in Suggested Organizations",
                                    collection_starred_events: "Your Starred Events",
                                    collection_starred_resources_visitor: "Events using Suggested Resources",
                                    collection_starred_spaces_visitor: "Events in Suggested Locations",
                                    collection_requested: "Events You Have Requested",
                                    collection_starred_resources: "Events using Your Starred Resources",
                                    collection_starred_events_viewer: "Featured Events",
                                },
                                contact_info: {
                                    work_email: "Work Email",
                                    contact_info_update_failure: "Contact information was not updated.",
                                    contact_info_update_success: "Contact information updated successfully!",
                                    contact_info_button: "Save Contact Information",
                                    work_phone: "Phone",
                                    contact_info_header: "Edit Your Contact Information",
                                    home_zip: "Zip / Postal Code",
                                    title: "Title",
                                    invalid_email: "Please enter a valid email address.",
                                    required_fields_message:
                                        "Last Name and Work Email are required fields. Please make sure both fields have valid information.",
                                    name_prefix: "Name Prefix",
                                    work_street_address: "Street Address",
                                    work_address_header: "Work Address",
                                    home_address: "Home Address",
                                    work_state: "State / Province",
                                    work_country: "Country",
                                    first_name: "First Name",
                                    email: "Email",
                                    home_phone: "Phone",
                                    work_city: "City",
                                    home_address_header: "Home Address",
                                    internal_id: "Internal ID",
                                    name_suffix: "Name Suffix",
                                    invalid_fields_message:
                                        "One or more of your email addresses is invalid. Please recheck and try again.",
                                    home_street_address: "Street Address",
                                    password_characters:
                                        "Passwords may contain only letters, numbers and underscore characters.",
                                    email_signature: "Email Signature",
                                    last_name: "Last Name",
                                    SMTP_username: "SMTP Username",
                                    home_email: "Home Email",
                                    work_zip: "Zip / Postal Code",
                                    middle_name: "Middle Name",
                                    home_state: "State / Province",
                                    contact_lock_failure:
                                        "Your contact information cannot edited at this time. It may be locked by an administrator or process.",
                                    SMTP_password: "SMTP Password",
                                    home_city: "City",
                                    home_country: "Country",
                                    basic_information_header: "Basic Information",
                                    copy_me: "Copy myself on all emails I send",
                                    home_fax: "FAX",
                                    work_fax: "FAX",
                                },
                                date_restriction_latest: "The latest that you can schedule a new event is",
                                browser_recommendations: {
                                    upgrade_ie:
                                        '<span>If you are using <a href="http://www.microsoft.com/windows/internet-explorer/" target="ie"><b>Internet Explorer</b></a>, please upgrade to the latest version.</span>',
                                    chrome_frame:
                                        '<span>Also, there is a plug-in for Internet Explorer that can help it run 25Live <i>faster</i> called <a href="http://www.google.com/chromeframe" target="frame"><b>Google Chrome Frame</b></a>. Try it!</span>',
                                    best_browsers:
                                        '<span>25Live works best and fastest in <a href="http://www.mozilla.com/firefox/" target="firefox"><b>Mozilla Firefox</b></a> and <a href="http://www.google.com/chrome/" target="chrome"><b>Google Chrome</b></a>.</span>',
                                    close: "Close",
                                    browser_recommendations: "Browser Recommendations",
                                },
                                icon_legend: {
                                    unavailable: "Location has Conflicts",
                                    pending: "Conflict check is Pending",
                                    available: "Location has no Conflicts",
                                    not_fav: "Item is not Starred",
                                    organization_fav: "Starred Organization",
                                    unflagged_disabled_note: "user cannot flag this task",
                                    icon_legend: "Icon Legend",
                                    event_fav: "Starred Event",
                                    unflagged_disabled: "Unflagged Task",
                                    noperms: "No Permissions for this Location",
                                    flagged: "Flagged Task",
                                    task_approved: "Task (Approved/Completed)",
                                    event_locked_note: "cannot be edited",
                                    location_fav: "Starred Location",
                                    share: "Location can be Shared",
                                    fav: "Item is Starred",
                                    location_layout: "Location Layout Diagram",
                                    event_object: "Event",
                                    flagged_disabled: "Flagged Task",
                                    event_occurrence: "Event Occurrence",
                                    resource: "Resource",
                                    task_cancelled: "Task (Cancelled)",
                                    flagged_disabled_note: "user cannot unflag this task",
                                    fav_note: "click to remove from Starred",
                                    location_image: "Location Image",
                                    event_edit: "Edit an Event",
                                    not_fav_note: "click to add to Starred",
                                    special_cursors: "Special Cursors",
                                    task_active: "Task (Active/Pending)",
                                    resource_fav: "Starred Resource",
                                    task_denied: "Task (Denied/Declined)",
                                    special_cursors_text:
                                        "<span>These cursors appear whenever your mouse hovers above the name of a clickable <i>event</i>, <i>location</i> or <i>resource</i>. <b>Clicking</b> on the name will load the default view for that item (usually the Detail view). <b>Right-clicking</b> the item will reveal a menu displaying all available views for the item (Detail, List, Calendar, etc.); choosing a view from this list will load that item using the selected view.</span>",
                                    unflagged: "Unflagged Task",
                                    organization: "Organization",
                                    location: "Location",
                                    event_create: "Create an Event",
                                    event_locked: "Locked Event",
                                },
                                image_new: {
                                    cancel: "Cancel",
                                    upload_image: "Upload Image",
                                    image_new_header: "Upload New Image",
                                    required_fields_message: "Image Name and Image File are required.",
                                    image_new_upload_failure: "There was an error uploading your image.",
                                    diagram: "Diagram",
                                    image_name: "Image Name",
                                    image_new_upload_success: "Image uploaded successfully!",
                                    photograph: "Photograph",
                                    image_new_button: "Upload New Image",
                                    image_file: "Image File",
                                    upload_limit: "Max file size: 5MB",
                                    image_type: "Image Type",
                                },
                                no_days: "None",
                                collections: {
                                    save_button: "Save",
                                    save_collection: "Save a Search",
                                    save_instructions:
                                        "To save this set of objects as a search, provide a name for your search below, then click Save.",
                                },
                                product_feedback: "Product Feedback",
                                date_restriction_earliest: "The earliest that you can schedule a new event is",
                                availability_guide: {
                                    button: "Hide this Guide",
                                    caption_reserve_resource:
                                        "Click an empty slot below to start creating an event using that resource at that time.",
                                    description_location:
                                        "<p>The <b>Availability View</b> shows the events scheduled in one or more locations on the selected date. It also shows any other restrictions on location availability, such as closing times. An empty time slot means the location is available at that time.</p>",
                                    header: "What is the Availability view?",
                                    for_more: "For more on this Availability view,",
                                    description_resource:
                                        "<p>The <b>Availability View</b> shows the events using one or more resources on the selected date. An empty slot means the resource is available at that time.</p>",
                                    click_here: "click here",
                                    caption_reserve_location:
                                        "Click an empty slot below to start creating an event in that location at that time.",
                                    caption_hover:
                                        "Hover over an event to see a brief summary, or click the event color bar to load full details.",
                                },
                                user_prefs: {
                                    uppercase: "Name (All Caps)",
                                    enter_old_password: "Please enter your original password.",
                                    need_username: "You must enter your username to change your password.",
                                    year: "Year",
                                    none: "None",
                                    week_start: "Week start day",
                                    space: "Space",
                                    change_password: "Change Your Password",
                                    number: "Number",
                                    change_password_button: "Change Password",
                                    date_order: "Date Order",
                                    time_display: "Time Display",
                                    availability_grid_hours_desc:
                                        "Select the starting and ending times you want displayed in Availability view.",
                                    day: "Day",
                                    date_separator: "Date Separator",
                                    reload_later_button: "Reload Later",
                                    period: "Period",
                                    start_day_of_week: "Start Day of Week",
                                    preferences_saved: "Preferences Saved.",
                                    example_date_time_display: "Example Date and Time Display",
                                    day_display: "Display Day as",
                                    initial_caps_example_month: "Example: Jan",
                                    user_preferences_button: "Update Preferences",
                                    reload_now_button: "Reload Now",
                                    uppercase_example_day: "Example: MON",
                                    initial_caps_example_day: "Example: Mon",
                                    date_time_display: "Date and Time Display",
                                    date_preferences: "Date/Time Preferences",
                                    month: "Month",
                                    display_start: "First Hour",
                                    password_change_success: "Your password was successfully updated.",
                                    start_day_of_week_desc: "Choose which day begins each week in calendar displays.",
                                    time_zone_instructions:
                                        "Set the time zone in which you want to display events in 25Live. Note that this only affects content that appears when you log in to 25Live.",
                                    read_more: "Read more.",
                                    calendar_days: "Calendar Start Day",
                                    password_change_fail:
                                        "Your password was not updated. Please contact your system administrator.",
                                    uppercase_example_month: "Example: JAN",
                                    hour_12: "12 hour clock",
                                    enter_new_password: "Please enter your new password.",
                                    invalid_password_length: "Passwords must be at least 5 characters in length.",
                                    month_display: "Display Month as",
                                    display_hours_conflict: "End Hour must be after Start Hour.",
                                    cancel_button: "Cancel",
                                    backward_slash: "Backward Slash",
                                    number_example_month: "Example: 1",
                                    availability_grid_hours: "Availability View Display Hours",
                                    hyphen: "Hyphen",
                                    hour_24: "24 hour clock",
                                    time_zone: "Time Zone",
                                    display_end: "Last Hour",
                                    user_time_zone: "Your Time Zone",
                                    comma: "Comma",
                                    initial_caps: "Name (Initial Caps)",
                                    calendar_preferences: "Calendar Preferences",
                                    new_password: "New Password",
                                    reload_note: "Note: You must reload your browser for changes to take effect.",
                                    contact_problem:
                                        "There was a problem retrieving your contact information. Please contact your system administrator to change your password.",
                                    invalid_password_characters:
                                        "Passwords may contain only letters, numbers and underscore characters.",
                                    forward_slash: "Forward Slash",
                                    current_password: "Current Password",
                                    username: "Username",
                                    dark_mode: "Dark Mode",
                                },
                                close: "Close",
                                all_days: "All Days",
                                pubdate: "Information is current as of",
                                sign_in: {
                                    sign_in_new_password: "Sign in with your new password.",
                                    new_password_confirm: "Confirm new password",
                                    contact_sysadmin: "Please contact your system administrator.",
                                    need_username: "You must enter your username to retrieve your password.",
                                    problem_with_reset: "There was a problem resetting your password.",
                                    sign_in_button: "Sign In",
                                    forgot_password_prompt: "Forgot your Password?",
                                    password: "Password",
                                    sign_in_header: "Sign In",
                                    problem_with_remind: "There was a problem emailing you your password.",
                                    remind_confirm: "Your password has been sent to the email address on record.",
                                    reset_confirm: "with a new password.",
                                    username: "Username",
                                },
                                sign_out: "Sign Out",
                                overdue_tasks: "Overdue Tasks",
                                contextmenu: {
                                    calendar: "Calendar",
                                    edit_event: "Edit Event",
                                    header: "Choose a View...",
                                    details: "Details",
                                    copy_event: "Copy Event",
                                    email_event: "Email Event Details",
                                    availability: "Availability",
                                    task_list: "Task List",
                                    list: "List",
                                },
                                refresh: "Refresh",
                                whats_new: "What's New",
                                email_options: {
                                    email_options_header: "Email Preferences",
                                },
                                help: "Help",
                                org_new: {
                                    cancel_button: "Cancel",
                                    org_new_update_success: "Organization added successfully!",
                                    org_new_update_failure: "Organization was not added.",
                                    billing_phone: "Phone",
                                    billing_street_address: "Street Address",
                                    custom_attributes_title: "Custom Attributes",
                                    loading_types: "Loading Types...",
                                    org_type: "Type",
                                    billing_country: "Country",
                                    org_new_button: "Create New Organization",
                                    billing_address_title: "Billing Address",
                                    loading_attributes: "Loading Custom Attributes...",
                                    required_fields_message: "Organization Name is required.",
                                    custom_attributes: {
                                        no: "No",
                                        select_an_organization: "Select an Organization...",
                                        attribute_desc_float:
                                            "Integers or floating point numbers. Examples: 8.25, 10.00, 12",
                                        yes: "Yes",
                                        organizations_assignment_restricted:
                                            "You do not have permission to assign Organizations.",
                                        attribute_desc_integer: "Integers only. Examples: 8, 10, 12",
                                        contacts_assignment_restricted:
                                            "You do not have permission to assign Contacts.",
                                        resource_assignment_restricted:
                                            "You do not have permission to assign Resources.",
                                        select_a_resource: "Select a Resource...",
                                        attribute_desc_file: "Path name of a file, URL or command line.",
                                        attribute_desc_string: "Maximum 80 alphanumeric characters.",
                                        select_a_contact: "Select a Contact...",
                                        select_a_space: "Select a Space...",
                                        space_assignment_restricted: "You do not have permission to assign Spaces.",
                                    },
                                    save_button: "Add Organization",
                                    billing_city: "City",
                                    billing_state: "State / Province",
                                    basic_info_title: "Basic Information",
                                    billing_zip: "Zip / Postal Code",
                                    org_title: "Organization Title",
                                    org_name: "Organization Name",
                                    billing_fax: "FAX",
                                    org_new_header: "Add Organization",
                                },
                                date_restriction_header: "Alert: Date Restriction",
                                multi_availability_guide: {
                                    button: "Hide this Guide",
                                    caption_reserve_resource:
                                        "Click an empty slot below to start creating an event at that time.",
                                    description_location:
                                        "<p>The <b>Multi-Day Availability View</b> is like the Availability View except that it shows all the events scheduled in multiple locations on selected dates. You are able to filter which days of the week are shown as well as highlight all the events for one location at a time.</p>",
                                    header: "What is the Multi-Day Availability view?",
                                    for_more: "For more on this Multi-Day Availability view,",
                                    description_resource:
                                        "<p>The <b>Multi-Day Availability View</b> is like the Availability View except that it shows all the events scheduled using multiple resources on selected dates. You are able to filter which days of the week are shown as well as highlight all the events for one location at a time.</p>",
                                    click_here: "click here",
                                    caption_reserve_location:
                                        "Click an empty slot below to start creating an event at that time.",
                                    caption_hover:
                                        "Hover over an event to see a brief summary, or click the event color bar to load full details.",
                                },
                                help_login_prompt: "You must Sign In to view 25Live Help.",
                                contact_new: {
                                    work_email: "Email",
                                    work_phone: "Phone",
                                    home_zip: "Zip / Postal Code",
                                    title: "Title",
                                    required_fields_message:
                                        "Last Name and Work Email are required fields. Please make sure both fields have valid information.",
                                    name_prefix: "Name Prefix",
                                    work_street_address: "Street Address",
                                    contact_new_update_failure: "Contact was not added.",
                                    work_address_header: "Work Address",
                                    home_address: "Home Address",
                                    work_state: "State / Province",
                                    contact_new_header: "Add Contact",
                                    work_country: "Country",
                                    first_name: "First Name",
                                    home_phone: "Phone",
                                    work_city: "City",
                                    home_address_header: "Home Address",
                                    internal_id: "Internal ID",
                                    name_suffix: "Name Suffix",
                                    home_street_address: "Street Address",
                                    last_name: "Last Name",
                                    home_email: "Email",
                                    work_zip: "Zip / Postal Code",
                                    middle_name: "Middle Name",
                                    home_state: "State / Province",
                                    contact_lock_failure:
                                        "Your contact information cannot be added at this time. It may already exist.",
                                    contact_new_update_success: "Contact added successfully!",
                                    home_city: "City",
                                    home_country: "Country",
                                    basic_information_header: "Basic Information",
                                    home_fax: "FAX",
                                    work_fax: "FAX",
                                    contact_new_button: "Create New Contact",
                                },
                                mobile_version: "Mobile Version",
                                privacy_policy: "Privacy Policy",
                                create_user: {
                                    cancel: "Cancel",
                                    msg_missing_fields: "Some required fields are missing.",
                                    msg_email_exists:
                                        "An account with this email address already exists. Please enter another email address, or contact your administrator to reset your password.",
                                    last_name: "Last Name",
                                    optional: "optional",
                                    msg_username_exists:
                                        "An account with this Username already exists. Please enter another Username, or contact your administrator to reset your password.",
                                    loading_msg: "Signing you in to 25Live...",
                                    create_account: "Create a 25Live Account",
                                    middle_name: "Middle Name",
                                    alert_success:
                                        "Account successfully created! You will now automatically be signed-in.",
                                    create_account_short: "Create Account",
                                    password: "Password",
                                    alert_failure: "Account creation failed. Please try again later.",
                                    phone: "Phone",
                                    create: "Create",
                                    first_name: "First Name",
                                    email: "Email",
                                    username: "Username",
                                },
                                lock_failed: "This event is currently locked. Please try again later.",
                            },
                            tooltips: {
                                loading_occurrence_summary: "Loading Occurrence Summary...",
                                pending: "(Pending)",
                                description: "Description",
                                layout_image: "Image",
                                type: "Type",
                                title: "Title",
                                layouts: "Layouts",
                                blackout: "Blackout",
                                cancelled_note: "[CANCELLED]",
                                reference: "Reference",
                                features: "Features",
                                loading_contact_summary: "Loading Contact Summary...",
                                enter_username: "Enter a username and password to access your personal 25Live.",
                                also_assign_warning:
                                    "This event has one or more resources assigned that are also required to assign ",
                                categories: "Categories",
                                loading_event_summary: "Loading Event Summary...",
                                email: "Email",
                                loading_location_summary: "Loading Location Summary...",
                                sign_out: "Click to sign out of 25Live.",
                                user_preferences:
                                    "Click to access and edit your preferences for 25Live or to change your password.",
                                no_perms_resource: "You cannot currently create new events using this resource.",
                                work: "Work",
                                max_capacity: "Max Capacity",
                                create_account: "Create an account for 25Live.",
                                loading_organization_summary: "Loading Organization Summary...",
                                home: "Home",
                                help: "Click for 25Live Help.",
                                no_perms_organization:
                                    "You cannot currently create new events using this organization.",
                                phone: "Phone",
                                organization: "Organization",
                                closed: "Closed",
                                locations: "Location(s)",
                                assigned: "Assigned",
                                loading_resource_summary: "Loading Resource Summary...",
                                no_perms_location: "You cannot currently create new events using this location.",
                            },
                        },
                        "s25-contact_select": {
                            text: {
                                select_one: "Select at least one contact.",
                                search: "Search",
                                search_contacts: "Search for Contacts",
                                loading: "Loading...",
                                starred_contacts: "Starred Contacts",
                                done: "Done",
                            },
                        },
                        "s25-reports": {
                            text: {
                                reports: "Run a Report",
                                choose_search: "Choose a Search...",
                                select_report_parameters: "Select Report Parameters",
                                run_report: "Run Report",
                                no_event_searches: "No available event searches",
                                report_requested: "you requested.",
                                selected_dates: "Selected dates must be between",
                                no_space_searches: "No available location searches",
                                email_multiple_recipients: "Email this report to...",
                                include_additional_text: "Include this additional text:",
                                search_name: "Search Name",
                                email_submitted:
                                    "Your report request was submitted and should arrive at your email soon.",
                                choose_org_type: "Choose an Organization Type...",
                                email_report: "Email this report to yourself",
                                about_this_report: "About This Report...",
                                report_success: "Report run successful",
                                choose_requirement: "Choose a Requirement Type...",
                                loading: "Loading",
                                view_sample_report: "View Sample Report",
                                start_time: "Start Time",
                                resource_collection: "Resource Search",
                                event_reports: "Event Reports",
                                resource_reports: "Resource Reports",
                                multiple_recipients_note: "Separate multiple recipients with a comma.",
                                end_date: "End Date",
                                no: "No",
                                report_format_options: "Report Format Options",
                                org_collection: "Organization Search",
                                end_date_after_start: "End dates must be after start dates.",
                                please_enter_email:
                                    "Please enter an e-mail address to which you wish to send the report.",
                                starred_reports: "Starred Reports",
                                email_submitted_someone_else: "Your report request was submitted and will be sent to",
                                no_resource_searches: "No available resource searches",
                                and: "and",
                                no_details_available: "No Details Available",
                                no_permission_reports: "You do not have permission to print reports.",
                                location_collection: "Location Search",
                                print_report: "View this report now",
                                close: "Close",
                                start_date: "Start Date",
                                date_range: "Date Range",
                                no_report_msg: "No report is available",
                                please_find: "Please find attached the",
                                yes: "Yes",
                                required_params:
                                    "This report has required parameters for which you have not selected values.",
                                org_type: "Organization Type",
                                end_time: "End Time",
                                report_emailed_success: "Report emailed successfully.",
                                other_reports: "Other Reports",
                                email_problem: "There was a problem with your request. Please try again.",
                                event_collection: "Event Search",
                                run_another: "Run another report",
                                report_delivery_options: "Report Delivery Options",
                                choose_report: "Choose a Report...",
                                space_reports: "Location Reports",
                                choose_report_list:
                                    "Choose a report from the list, then complete the parameters to run the report.",
                                report_name: "Report Name",
                                no_matching_reports: "No Matching Reports",
                            },
                            tooltips: {
                                reload: "Reload",
                                help_with_view: "Help with this view",
                                add_to_starred_reports: "Add to Your Starred Reports",
                                remove_from_starred_reports: "Remove from Your Starred Reports",
                            },
                        },
                        "s25-event_details": {
                            text: {
                                status_denied: "Denied",
                                registered: "registered",
                                current_registrant_count: "Registrant Count",
                                complete_all: "Complete All Your Active Tasks",
                                rate_schedule: "Rate Schedule",
                                state: "State",
                                action_assign: "Assign",
                                has_been_invoiced: " has been invoiced.",
                                apply_new_date: "Apply New Date",
                                audit_remove_location: "Remove Location",
                                edit: "edit",
                                attendees: "Attendees",
                                expected: "expected",
                                action_deny: "Deny",
                                creation_date: "Creation Date",
                                action_edit: "Edit this Event",
                                takedown: "Takedown End",
                                no_attendee_limit: "no limit specified",
                                tasks_completed: "Tasks Completed",
                                change_to_approve: "(Change to Approve?)",
                                subtotal: "Subtotal",
                                confirm_change_state: "Do you still want to change the event state?",
                                include_related: "Show Related Events",
                                actions: "More Actions... ",
                                order_id: "Order ID",
                                respond_by_date: "Respond By Date",
                                end_date: "End Date",
                                resource_instructions: "Resource Instructions",
                                prompt_approve: "Approve?",
                                occurrence_start_dates: "Occurrence Start Dates",
                                audit_info: "Additional Information",
                                state_sealed: "Sealed",
                                confirm_delete: "Delete this event?",
                                status_in_progress: "In Progress",
                                to_do: "To Do",
                                event_not_deleted_msg:
                                    "There was a problem with your request. The event was not deleted.",
                                event_details: "Event Details",
                                grand_total: "Grand Total",
                                close: "Close this Event",
                                email: "Email Event Details",
                                prompt_ignore: "Ignore?",
                                view_tasklist: "View this event's Task List",
                                calendar: "Calendar",
                                your_assignment_state: "Your Assignment State",
                                task_status: {
                                    approved: "Approved",
                                    cancelled: "Cancelled",
                                    active: "Active",
                                    assigned: "Assigned",
                                    denied: "Denied",
                                },
                                vcal_to_dos: "vCalendar To Do's",
                                respond_by_date_option_all_active: "All Active Approval Tasks",
                                refresh: "Refresh",
                                additional_organizations: "Additional Organization(s)",
                                bound_events: "Bound Events",
                                list_price: "List Price",
                                occurrence_exception: "This occurrence has an exception.",
                                action_copy_with_related: "Copy this Event and its Related Events",
                                charge_to: "Charge To",
                                days: "days,",
                                prompt_assign: "Assign?",
                                cabinet: "Cabinet",
                                instructions: "Instructions",
                                prompt_complete: "Complete?",
                                notes: "Internal Notes",
                                attend: "Indicate which occurrences you plan to Attend",
                                all_tasks_section: "All Tasks in this Section",
                                debit_account: "Debit Account",
                                reply_sent: "A vCalendar reply has been generated for this Event.",
                                fav_add: "Not Starred (Add to Starred?)",
                                view: "View",
                                prompt_unassign: "UnAssign?",
                                approvals: "Approvals",
                                no_org: "This event does not have a primary organization, so its pricing cannot be adjusted in 25Live.",
                                details: "Details",
                                zero_hours: "0 hours,",
                                respond_by_date_option_header: "Apply new Respond By Date to",
                                occurrences_yr_attending: "Occurrences You Are Attending",
                                fav_remove: "Starred (Remove from Starred?)",
                                event_cust_atrbs: "Event Custom Attributes",
                                status_active: "Active",
                                following_occurrences: "following occurrences",
                                prompt_acknowledge: "Acknowledge?",
                                event_title: "Event Title",
                                tickets_header: "Tickets",
                                nonexistent_msg: "This event does not exist, or has been deleted.",
                                sharing: "Sharing",
                                current_attendee_count: "Attendee Count",
                                no_matching_tasks: "There are no tasks that meet the selected criteria.",
                                event_occurrences: "Event Occurrences",
                                start_time: "Start Time",
                                name: "Name",
                                receipt: "Receipt",
                                adjustment: "Adjustment",
                                billing_list: "Pricing",
                                has_not_been_invoiced: " has not been invoiced.",
                                generate_counter: "generate counter",
                                action_relate: "Manage Related Events",
                                sharing_allowed: "This location can be shared during this occurrence",
                                default_view_details: 'Make "Details" my default view when loading individual Events',
                                registration_type: "Registration",
                                pre_event_start: "Pre-Event Start",
                                action_approve: "Approve",
                                scheduler: "Scheduler",
                                no_tasks: "This event has no associated tasks.",
                                event_preferences: "Event Preferences",
                                location_layout: "Location Layout",
                                action_expand: "Expand",
                                revise_attend: "Revise which occurrences you plan to Attend",
                                respond_by_date_option_all_assigned: "All Active Approval Tasks Assigned To You",
                                applyweb_form_code: "Registration Form Code",
                                summary: "Summary",
                                subtotals: "Subtotals",
                                default_view_pricing: 'Make "Pricing" my default view when loading individual Events',
                                hours: "hours,",
                                resource_prefs: "The following resource preferences were registered",
                                tasks_for_reservation: "Tasks for Reservation ",
                                confirmation_notice: "Confirmation Notice",
                                total_occurrences: "total occurrences",
                                yes: "Yes",
                                none_parens: "(none)",
                                maximum_head_count: "Maximum Head Count",
                                not_enough_OLS:
                                    "You do not have sufficient security access to change this event's state.",
                                denial_msg: "You do not have permission to view this event.",
                                layout: "Layout",
                                state_confirmed: "Confirmed",
                                state_tentative: "Tentative",
                                publish: "Send this Event to the 25Live Publisher",
                                setup: "Setup Start",
                                location: "Location",
                                action_bind: "Manage Bindings",
                                create_new_adjustment: "new",
                                location_prefs_reservation: "The following location preferences were registered for",
                                action_collapse: "Collapse",
                                assignments: "Assignments",
                                assignment_text: "Assignment Date and Time",
                                taxes: "Taxes",
                                default_view_audit_trail:
                                    'Make "Audit Trail" my default view when loading individual Events',
                                none: "none",
                                type: "Type",
                                remove_preferences: "Remove preferences",
                                dates_assigned: "Dates Assigned",
                                event_type: "Event Type",
                                view_receipt: "View Receipt",
                                of_this_event: " of this Event",
                                total_colon: "Total:",
                                price: "Price",
                                preceding_occurrences: "preceding occurrences",
                                change_to_deny: "(Change to Deny?)",
                                default_view_calendar: 'Make "Calendar" my default view when loading individual Events',
                                action_state: "Event State",
                                reservation: "Reservation",
                                pricing_date: "Pricing Date:",
                                rate_group: "Rate Group",
                                action_generate_vcal: "Generate vCalendar Reply/Counter for this Event",
                                status_declined: "Declined",
                                item: "Item",
                                audit_action: "Action",
                                dates: "Dates",
                                head_count: "Head Count",
                                confirmation: "Confirmation Text",
                                prompt_remove: "Remove?",
                                generate_reply: "generate reply",
                                location_instructions: "Location Instructions",
                                related_locations: "Related Location(s)",
                                phone: "Phone",
                                bound_to: "Bound to",
                                alien_uid: "Alien UID",
                                no_registrants: "Currently there are no registrants for this event.",
                                state_cancelled: "Cancelled",
                                create_todo: "Create To Do Task",
                                associated_reservation: "Associated Reservation",
                                notifications: "Notifications",
                                status: "Status",
                                tasks_for: "Tasks for ",
                                action_tweet: "Tweet this Event",
                                vcal_sent: "Your vCalendar reply/counter was sent, the result is:",
                                save: "Save",
                                audit_date: "Date",
                                more_info: "More info?",
                                req_comments: "Requirement Comments:",
                                occurrence: "Occurrence",
                                registration_rsvp: "Simple Sign-up/RSVP",
                                audit_user: "User",
                                retrieving_info: "Retrieving Information...",
                                all_your_tasks_section: "All Your Tasks in this Section",
                                counter_sent: "A vCalendar counter has been generated for this Event.",
                                attendee_details: "Attendee Details",
                                vcal_not_sent: "Your vCalendar reply/counter was not sent, because:",
                                event_dates: "Event Dates:",
                                event_requirements: "Requirements",
                                ecommerce: "E-Commerce",
                                status_acknowledged: "Acknowledged",
                                delete_event: "Delete this Event",
                                start_date: "Start Date",
                                assigned_to: "Assigned To",
                                no_report_msg: "No report is available",
                                registration_URL: "Registration Form URL",
                                default_view_task_list:
                                    'Make "Task List" my default view when loading individual Events',
                                prompt_deny: "Deny?",
                                counter_not_sent: "A vCalendar counter has not been generated for this Event:",
                                end_time: "End Time",
                                all_contacts_must_approve: "All contacts must approve.",
                                not_registered:
                                    "You are not currently registered to attend any occurrences of this event.",
                                related_events: "Related Events",
                                email_header: "Email",
                                audit_assign_resource: "Assign Resource",
                                action_unflag: "Unflag",
                                organization: "Organization",
                                report: "Print a Report for this Event",
                                vcal_not_sent_nonspecific:
                                    "Your vCalendar reply/counter was not sent, there was an error.",
                                not_scheduler_or_requestor:
                                    "Registration data is only available to event schedulers and\trequestors.",
                                action_copy: "Copy this Event",
                                adjustments: "Adjustments",
                                status_complete: "Complete",
                                reply_not_sent: "A vCalendar reply has not been generated for this Event:",
                                location_prefs: "The following location preferences were registered",
                                unassignment: "UnAssignment",
                                action_flag: "Flag",
                                audit_assign_location: "Assign Location",
                                reference: "Reference",
                                add_adjustment: "Add an Adjustment",
                                day: "day,",
                                all: "All",
                                status_approved: "Approved",
                                minutes: "minutes",
                                action_take_ownership: "Take Ownership of this Event",
                                status_cancelled: "Cancelled",
                                save_problem: "There was a problem updating the billing date.",
                                event_name: "Event Name",
                                post_event_end: "Post-Event End",
                                first_date: "First Date",
                                resource_quantity: "Resource Quantity",
                                lock_msg: "This event is currently locked. Please try again later.",
                                attendance_list: "Attendance",
                                to_dos: "To Do's",
                                event_categories: "Event Categories",
                                no: "No",
                                tickets: "Get Tickets for this Event",
                                problem_with_request: "There was a problem with your request.",
                                resource_prefs_reservation: "The following resource preferences were registered for",
                                all_tasks: "All Assigned Tasks",
                                notified_fyi: "Notified [FYI]",
                                respond_by_date_option_only_this: "Only This Approval Task",
                                total: "Total",
                                hour: "hour,",
                                flagged: "Flagged?",
                                default_view_ecommerce:
                                    'Make "E-Commerce" my default view when loading individual Events',
                                assignment_requests: "Assignment Requests",
                                no_tasks_for_you: "This event has no associated tasks assigned to you.",
                                task_list: "Task List",
                                loading_occurrence_details: "Loading Occurrence Details...",
                                audit_remove_resource: "Remove Resource",
                                quantity: "Quantity",
                                comments: "Comments",
                                edit_adjustment: "Edit Adjustment",
                                subscribe: "Subscribe to this Event",
                                view_related_events: "Open All",
                                credit_account: "Credit Account",
                                resources: "Resources",
                                totals: "Totals",
                                click_here: "Click Here",
                                locations_resources: "Locations & Resources",
                                requestor: "Requestor",
                                minute: "minute",
                                online_registrations: "Online Registrations",
                                audit_trail: "Audit Trail",
                                administrative: "Event",
                                organizations: "Organizations:",
                                locations: "Locations",
                                state_denied: "Denied",
                                invoice: "Invoice",
                                your_tasks: "Only Tasks Assigned To You",
                                select_attendee_count:
                                    "Select a Current Attendee Count at left to view a list of attendees.",
                            },
                            tooltips: {
                                collapse_this_occurrence: "Collapse this Occurrence",
                                view_following_occurrences: "Click to view all following occurrences for this event.",
                                approve_all: "Approve all tasks assigned to you.",
                                expand_this_occurrence: "Expand this Occurrence",
                                occurrence_cancelled: "This occurrence has been cancelled.",
                                flag_task: "Flag this task for further attention.",
                                close_view: "Close this view.",
                                edit_comments: "Edit Comments",
                                collapse_all_occurrences: "Collapse all occurrences",
                                occurrence_exception: "This occurrence has an exception.",
                                help_with_view: "Help for this view",
                                view_preceding_occurrences: "Click to view all preceding occurrences for this event.",
                                occurrence_override: "This occurrence was scheduled as an override to a constraint.",
                                unflag_task: "Lower the flag to ignore the task.",
                                expand_all_occurrences: "Expand all occurrences",
                                expand_this_task: "Expand This Task",
                                deny_all: "Deny all tasks assigned to you.",
                                comment_instructions: "Enter or edit comments, then click Save.",
                                occurrence_warning:
                                    "There is a warning constraint during the dates of this occurrence.",
                                occurrence_constraint: "This occurrence has been excluded due to a constraint.",
                                collapse_this_task: "Collapse this task",
                            },
                        },
                        "s25-search_edit": {
                            text: {
                                btn_save_search: "Save Search",
                                rename_search: "Rename Search",
                                current_name: "Current Name:",
                                new_name: "New Name:",
                            },
                            tooltips: "",
                        },
                        "s25-invoice": {
                            text: {
                                invoice_event: "Create Invoice",
                            },
                            tooltips: {
                                help_with_invoicing: "Help with invoicing",
                            },
                        },
                        "s25-event_search_advanced": {
                            text: {
                                search_groupings: "Search Groupings",
                                popular_events: "Featured Events",
                                index_keyword: "Index",
                                events_in_starred_locations: "Events In Your Starred Locations",
                                no_location_layouts: "No Location Layouts",
                                type: "Type",
                                index_type: "Types",
                                someone_elses: "You can only delete searches you created.",
                                reference: "Reference",
                                search_problem_org:
                                    "You are not currently associated with an organization. Please contact your system administrator.",
                                events_using_starred_resources: "Events Using Your Starred Resources",
                                index_event_collection_default: "Public Event Searches",
                                matching_events: "Matching Events",
                                loading_occurrences: "Loading Event Occurrences...",
                                search_renamed: "was successfully renamed.",
                                index_event_collection_all: "All of Your Event Searches",
                                index_space_collection: "Your Starred Location Searches",
                                events_sponsored_by_starred_orgs: "Events Sponsored By Your Starred Organizations",
                                fav: "Starred (Remove from Starred?)",
                                state: "State",
                                events_requested: "Events You Have Requested",
                                no_collections: "No Searches",
                                no_recent_events: "No individual events have been recently viewed.",
                                events_with_tasks: "Events With Tasks Assigned To You",
                                rename_someone_elses: "You can only rename searches you created.",
                                rename_search: "Rename Search",
                                use_location_tab: "Please edit Location searches on the Your Location Searches tab.",
                                drafts_requested: "Event Drafts You Have Requested",
                                retrieving_events: "Retrieving Events...",
                                no_resource_categories: "No Resource Categories",
                                search_deleted: "was successfully deleted.",
                                dates: "Dates:",
                                choose_collection_name: "Please choose a name for your search.",
                                loading: "Loading...",
                                search_problem_alert: "There was a problem with your search.",
                                list: "List",
                                your_role: "Your Role",
                                index_space_collection_default: "Public Location Searches",
                                no_location_collections: "No Location Searches",
                                search_already_exists:
                                    "A search with this name already exists. Please choose a different search name.",
                                no_suggested_contacts: "No Suggested Contacts",
                                name: "Name",
                                index_organization: "Organizations",
                                events_attending: "Events You Are Attending",
                                events_additional_role: "Events in which you have an additional role",
                                actions: "Actions",
                                index_cabinet: "Cabinets",
                                advanced_search_header: "Advanced Event Search",
                                browse_instructions:
                                    'Select a Search Grouping (left) to browse existing searches, or click the "New Search" button.',
                                delete_search: "Delete Search",
                                no_access_msg: "You have not selected any events to view.",
                                index_role: "Roles",
                                columns: "Choose Visible Columns",
                                retrieving_criteria: "Retrieving Criteria...",
                                hot_events: "Hot Events",
                                more_info: "More info?",
                                not_fav: "Not Starred (Add to Starred?)",
                                get_tickets: "Get Tickets",
                                title: "Title",
                                no_event_search: "You have not yet selected an Event Search.",
                                no_results: "No Results",
                                rename_problem: "There was a problem renaming your search.",
                                edit_search_criteria: "Edit Options",
                                events_scheduling: "Events You Are Scheduling",
                                space_search_instructions:
                                    "When you run a Location Search, your results will be the events to which those locations are assigned.",
                                events_instructor: "Events in which you are the Instructor",
                                no_location_features: "No Location Features",
                                no_fav_contacts: "No Starred Contacts",
                                index_event_collection_keyword: "Supplementary Keyword Searches",
                                events_sponsored_by_contact_orgs:
                                    "Events Sponsored By Organizations To Which You Belong",
                                categories: "Categories",
                                edit_event_collection: "Rename Event Search",
                                start_date: "Start Date",
                                calendar: "Calendar",
                                index_collection: "Your Starred Event Searches",
                                index_state: "States",
                                index_space_collection_all: "All of Your Location Searches",
                                drafts_scheduling: "Event Drafts You Are Scheduling",
                                refresh: "Refresh",
                                no_resource_collections: "No Resource Searches",
                                individual_events: "Individual Events You Have Open",
                                delete_problem: "There was a problem deleting your search.",
                                no_options: "No options",
                                featured_events: "Featured Events",
                                viewing_results_for: "You are viewing results for",
                                your_starred_events: "Your Starred Events",
                                index_category: "Categories",
                                no_location_categories: "No Location Categories",
                                organization: "Organization",
                                report: "Print a Report",
                                and_greater: "and greater",
                                custom_groups: "Pre-Defined Groups",
                                attend_event: "Attend This Event",
                            },
                            tooltips: {
                                add_to_starred: "Add to Your Starred Events.",
                                edit_event: "Edit this event.",
                                columns: "Turn individual columns on and off to customize the display.",
                                publisher: "Send events displayed below to the 25Live Publisher.",
                                remove_from_starred: "Remove from Your Starred Events.",
                                help_with_this_view: "Help for this view",
                            },
                        },
                        "s25-rsrv_wiz": {
                            text: {
                                cancel: "CANCEL and return to the event",
                                keyboard_shortcuts: {
                                    end_note:
                                        "Note: Keyboard shortcuts only work when a form field or link in the Event Scheduler has focus.",
                                    keyboard_shortcuts_header: "Event Scheduler Keyboard Shortcuts",
                                    next_element: "Next Element",
                                    arrow: "arrow",
                                    ctrl: "Ctrl",
                                    shift: "Shift",
                                    save: "Save",
                                    one_hour: "Adjust Time in increments of 1 Hour",
                                    increment: "Adjust Time in increments of INCREMENT_VALUE Minutes",
                                    event_ref: "EVENT REFERENCE",
                                    timespinner_shortcuts: "Time-Spinner Shortcuts",
                                    down: "DOWN",
                                    tab: "Tab",
                                    enter: "Enter",
                                    up: "UP",
                                    key: "Key",
                                    previous_section: "Previous Section",
                                    try_it_out: "Try it out",
                                    next_section: "Next Section",
                                    cancel_close: "Cancel / Close",
                                    loading: "Loading...",
                                    target: "Target",
                                    esc: "Esc",
                                    dropdown_note:
                                        "If you are navigating <b>dropdown fields</b> via the keyboard, press UP or DOWN to move among the options, then press ENTER to select the desired option.",
                                    event_saved_request: "Your event was saved as a request.",
                                },
                                headcount: {
                                    expected: "Expected",
                                    none_entered: "None Entered",
                                    registered: "Registered",
                                    expected_warning:
                                        "Your expected head count exceeds total max capacity of the event's locations.",
                                    spinner_instructions: "Select this option to enter a head count.",
                                    registered_warning:
                                        "Your registered head count exceeds the total max capacity of the event's locations.",
                                    not_editable: "Head Count is not currently editable.",
                                    headcount_warning:
                                        "Your specified head count exceeds the total max capacity of the event's locations.",
                                    registered_not_editable: "Registered Head Count is not currently editable.",
                                    expected_not_editable: "Expected Head Count is not currently editable.",
                                    unknown: "Unknown",
                                },
                                browse_resources: "Browse Resources...",
                                none: "(none)",
                                task_request_resources:
                                    "A task request has been issued for assignment of the following resource(s):",
                                resource_unavailable: "One or more of the resources selected is unavailable.",
                                button_previous: "Previous",
                                required: "required",
                                conflict_msg:
                                    "There are incomplete or invalid fields, or there is a conflict involving a Location or Resource.",
                                space_in_use: "The location you selected is in use, it was not assigned to the event.",
                                choose_from: "Choose from...",
                                no_conflicts: "No Scheduling Conflicts Detected!",
                                edit_event_details: "Edit Event Details...",
                                choose_from_resource_collections: "Choose from Resource Searches...",
                                state: {
                                    cancelled_warning:
                                        "Saving an event in the Cancelled state releases any location and resource assignments. Only users with the required security permissions may edit an event that has been cancelled.",
                                    sealed: "Sealed",
                                    default_prompt: "Set as your default Event State?",
                                    select_your_state: "Select Your Event State",
                                    default_updated: "Your default event State has been updated.",
                                    tentative: "Tentative",
                                    state_required: "Event state is required.",
                                    confirmed: "Confirmed",
                                    sealed_warning:
                                        "Only users with the required security permissions may edit an event saved in the Sealed state.",
                                    draft: "Draft",
                                    selected_state: "Selected Event State",
                                    cancelled: "Cancelled",
                                    denied_warning:
                                        "Saving an event in the Denied state releases any location and resource assignments. Only users with the required security permissions may edit an event that has been denied.",
                                    denied: "Denied",
                                    not_editable: "Event State is not currently editable.",
                                },
                                red_attention:
                                    "Areas that require your attention are highlighted in red. Please make any necessary changes and save again.",
                                event_status: "Event Status",
                                no_roles_for_type: "There are no contact roles for this event type.",
                                untitled: "Untitled #",
                                requires_full_control:
                                    "You must have full control on event edit to enable Online Registration.",
                                unspecified: "Unspecified",
                                edit: "edit",
                                loading_no_ellipsis: "Loading",
                                modify_selected_occurrences: "Modify Selected Occurrences",
                                browse_more_resources: "Browse More Resources...",
                                choose_suggested_orgs: "Choose from Suggested Organizations...",
                                loading_event: "Loading Event",
                                loading: "Loading...",
                                check_all: "Check All",
                                save_before_configuring: "You must save your event before configuring registration.",
                                loading_template: "Loading Event Template...",
                                no_fav_orgs: "No Starred Organizations",
                                saving: "Saving",
                                validating: "Validating",
                                copy_of: "Copy of",
                                requested_locations: "This event has requested locations.",
                                location_request_denied:
                                    "A location request for this event was denied. Please contact your system administrator for details.",
                                none_selected: "none selected",
                                must_select_type: "You must first select an Event Type.",
                                comments_label: "Comments",
                                no_space_categories: "No Space Categories",
                                additional_dates: "Additional Dates:",
                                checking_for_conflicts: "Checking for Scheduling Conflicts...",
                                occurrence: "Occurrence:",
                                button_save: "Save",
                                earliest_start_date: "The earliest that you can schedule a new event is",
                                no_results: "No Results",
                                resource_pref_saved: "A resource preference was created for the resource you selected.",
                                check_again: "Check again?",
                                search: "Search",
                                finished_editing: "Finished editing this event?",
                                info_not_available: "This information is not available.",
                                disabled: "Disabled",
                                categories: "Categories",
                                choose_from_all_types: "Choose from All Event Types...",
                                no_available_types: "No Available Event Types",
                                save_before_close: "SAVE this event before closing",
                                evtype: {
                                    suggested_evtypes: "Suggested Event Types",
                                    all_evtypes: "All Event Types",
                                    no_suggested_evtypes: "No Suggested Event Types",
                                    no_fav_evtypes: "No Starred Event Types",
                                    selected_evtype: "Selected Event Type",
                                    your_fav_evtypes: "Your Starred Event Types",
                                    select_evtype: "Select an Event Type...",
                                    cannot_change_evtype: "You cannot change the event type.",
                                    changing_evtype: "Changing the Event Type resets the options available for:",
                                    no_evtypes: "No Event Types",
                                    ecommerce_warning: "E-Commerce is not enabled for this Event Type.",
                                },
                                button_finish: "Finish",
                                what_next: "What would you like to do next?",
                                choose_resource_collection: "Choose a Resource Search",
                                event_reference: "Event Reference:",
                                search_results_for: "Search Results for ",
                                browse_orgs: "Browse Organizations...",
                                no_finished: "NO, I'm not done",
                                bound_events: "This event is bound to another event(s).",
                                affirmation: {
                                    i_agree: "I Agree",
                                    unchecked: "Unchecked",
                                    checked: "Checked",
                                    affirmation_required: "You must check the checkbox.",
                                },
                                event_registration: "Event Registration",
                                confirm: {
                                    has_conflicts:
                                        "Your event has Location and/or Resource conflicts. Do you still wish to save? Certain Location/Resource reservations may be lost.",
                                    button_continue: "CONTINUE with save",
                                    button_cancel: "CANCEL and return to the event",
                                },
                                allow_enroll:
                                    'Event type must have the "Allow Enrollment" custom attribute for Simple Sign-up and both "Allow Enrollment" and "Register Here" for Online Registration.',
                                diagnose: {
                                    date_problem:
                                        " Cannot find a place to route this event. Please check the event type, the date and the organization name.",
                                    cancel_editing: "CANCEL editing this event",
                                    i_dont_know_caveat:
                                        "If you do not choose a heading, this event will be saved as a Draft, and any Locations or Resources you selected will only be assigned as a preference.",
                                    view_details: "VIEW details of the event",
                                    insufficient_permission:
                                        "You do not have sufficient permission to save this event. Please check the event type, organization and location.",
                                    which_heading: "Under which heading would you like to save this event?",
                                    unknown_error:
                                        "An error occurred when saving your reservation. You may choose to continue editing or to cancel.",
                                    save_as_draft:
                                        "Click save to store this event as a request, and a heading will be chosen by a system administrator. Click Close to cancel this event.",
                                    continue_editing: "CONTINUE editing this event",
                                    location_not_avail:
                                        "The location you chose is not available. Please choose another location, or contact your system administrator.",
                                    insufficient_security:
                                        "You do not have sufficient security access to save this event. Please contact your administrator for more information. Click Close to cancel this event.",
                                    cannot_be_edited:
                                        "An error occurred when saving your reservation. This event cannot be changed in 25Live.",
                                    i_dont_know: "I Don't Know",
                                    event_locked:
                                        "This event is locked against editing.  Most likely another user has it open.  You can save it without location or resource assignments, or you can close and attempt editing another time.",
                                },
                                maximum: "Maximum:",
                                task_request_locations:
                                    "A task request has been issued for assignment of the following location(s):",
                                custom_attribs:
                                    'Event type must have both "Allow Registration" and "Register Here" custom attributes to enable Online Registration.',
                                contacts: {
                                    no_matching_contacts: "No Matching Contacts",
                                    no_assigned_contacts: "There are no Contacts assigned to this Event.",
                                    no_event_orgs: "No Contacts available for this event's organizations.",
                                    no_available_roles: "No Available Contact Roles",
                                    select_your_contact: "Select Your Contact",
                                    your_fav_contacts: "Your Starred Contacts",
                                    suggested_contacts: "Suggested Contacts",
                                    selected_contact: "Selected Contact",
                                    no_suggested_contacts: "No Suggested Contacts",
                                    contact_roles_required: "Contact roles are required.",
                                    no_fav_contacts: "No Starred Contacts",
                                    not_editable: "Event Contact Roles are not currently editable.",
                                    search_by_contact_name: "Search by Contact Name...",
                                },
                                location_pref_saved: "A location preference was created for the location you selected.",
                                choose_starred_resources: "Choose from Your Starred Resources...",
                                print_details: "Print details of this event.",
                                button_close: "Close",
                                requested_resources: "This event has requested resources.",
                                space: {
                                    preferred_locations: "The following locations were requested for this event:",
                                    hide_unavailable: "Hide Unavailable",
                                    no_location_layouts: "No Location Layouts",
                                    all_occurrences: "All Occurrences",
                                    features: "Features:",
                                    location_in_use_retry: "This location is in use. Please try another location.",
                                    preferred_location_attributes:
                                        "A location with the following attributes was requested for this event:",
                                    blackout_notification:
                                        "has blackout conflicts, but your blackout override permission allows you to reserve the space.",
                                    no_location_features: "No Location Features",
                                    conflicts: "Conflicts:",
                                    override: "Override Blackouts, Open/Close Hours",
                                    matching_locations: "Matching Location Requests",
                                    setup_instructions: "Setup Instructions:",
                                    location_required: "Event must have a location assigned.",
                                    suggested_locations: "Suggested Locations",
                                    selected_locations: "Selected Locations",
                                    no_starred_locations: "No Starred Locations",
                                    max_capacity: "Max Capacity:",
                                    search_by_location_name: "Search by Location Name:",
                                    openclose_notification:
                                        "has open or close hour conflicts, but your override permission allows you to reserve the space.",
                                    your_starred_locations: "Your Starred Locations",
                                    occurrence_infobox: {
                                        date: "Date",
                                        conflict_hours: "Location Hours Conflicts",
                                        share_reservation: "This reservation allows for sharing of this location.",
                                        max_capacity: "Max Cap.",
                                        conflict_blackout: "Location Blackout Conflicts",
                                        none: "none",
                                        layout: "Layout",
                                        copy_to_all: "Copy to all following occurrences",
                                        conflicts: "Conflicts?",
                                        share: "Share?",
                                        edit_instructions: "Edit Setup Instructions",
                                        conflict_reservation: "Existing Reservation Conflicts",
                                        setup_instructions: "Setup Instructions",
                                        assign: "Assign?",
                                        conflict_pending: "Pending Reservation Conflicts",
                                    },
                                    selected_location: "Selected Location",
                                    layout: "Layout:",
                                    no_location_collections: "No Location Searches",
                                    select_your_locations: "Select Your Locations",
                                    no_location_categories: "No Location Categories",
                                    no_matching_locations: "No Matching Locations",
                                    location_unavailable: "One or more of the locations selected is unavailable.",
                                    select_your_location: "Select Your Location",
                                    no_suggested_locations: "No Suggested Locations",
                                    not_editable: "Event Locations are not currently editable.",
                                },
                                features: "Features:",
                                closed_disabled: "This button is temporarily disabled.",
                                datetime: {
                                    no_occurrence_comments: "(No occurrence comments)",
                                    cancelled_occurrences: "This event has cancelled occurrences on: ",
                                    repeats_weekly: "Repeats Weekly",
                                    repeats_adhoc: "Repeats Ad Hoc",
                                    pre_event: "Pre-Event:",
                                    duration: "Occurrence Duration:",
                                    post_event_durations: "Post-Event/Takedown durations?",
                                    post_event: "Post-Event:",
                                    hour: "Hour",
                                    pre_event_setup: "Pre-Event/Setup durations?",
                                    repeats_monthly: "Repeats Monthly",
                                    end: "End:",
                                    same_day: "Same Day",
                                    day: "Day",
                                    no_repeat: "No, Does Not Repeat",
                                    hours: "Hours",
                                    minutes: "Minutes",
                                    start: "Start:",
                                    active: "Active",
                                    minute: "Minute",
                                    takedown: "Takedown:",
                                    reserved: "Reserved",
                                    repeats_daily: "Repeats Daily",
                                    setup: "Setup:",
                                    cancelled: "Cancelled",
                                    days: "Days",
                                    event_repeats: "Event Repeats?",
                                    repeats_yearly: "Repeats Yearly",
                                    not_editable: "Event Date and Time are not currently editable.",
                                    cancelled_occurrence: "This event has a cancelled occurrence on: ",
                                },
                                do_you_want_to_save: "Do you want to save the changes you made to this event?",
                                warning: "Warning:",
                                create_reg_form: "Create a registration form",
                                last_saved: "Last Saved:",
                                choose_from_sugg_resources: "Choose from Suggested Resources...",
                                resource: {
                                    max_quantity:
                                        "The maximum quantity available for this resource during this occurrence is",
                                    suggested_resources: "Suggested Resources",
                                    hide_unavailable: "Hide Unavailable",
                                    selected_resources: "Selected Resources",
                                    no_starred_resources: "No Starred Resources",
                                    select_your_resource: "Select Your Resource",
                                    all_occurrences: "All Occurrences",
                                    resource_unavailable: "One or more of the resources selected is unavailable.",
                                    cannot_reserve: "Cannot reserve more than",
                                    select_your_resources: "Select Your Resources",
                                    search_by_resource_name: "Search by Resource Name:",
                                    conflicts: "Conflicts:",
                                    setup_instructions: "Setup Instructions:",
                                    selected_resource: "Selected Resource",
                                    quantity: "Quantity:",
                                    no_resource_collections: "No Resource Searches",
                                    no_resource_categories: "No Resource Categories",
                                    no_suggested_resources: "No Suggested Resources",
                                    no_matching_resources: "No Matching Resources",
                                    occurrence_infobox: {
                                        date: "Date",
                                        quantity: "Quantity",
                                        copy_to_all: "Copy to all following occurrences",
                                        edit_instructions: "Edit Setup Instructions",
                                        setup_instructions: "Setup Instructions",
                                        assign: "Assign?",
                                        quantity_available: "Avail/Total",
                                    },
                                    resource_in_use_retry: "This resource is in use. Please try another resource.",
                                    preferred_resources: "The following resources were requested for this event:",
                                    hide_unavailable_resources: "Hide Unavailable Resources",
                                    preferred_resource_attributes:
                                        "A resource with the following attributes was requested for this event:",
                                    copy_to_all: "",
                                    resource_no_permission:
                                        "You do not have sufficient permission to assign this resource.",
                                    your_starred_resources: "Your Starred Resources",
                                    resource_required: "Event must have a resource assigned.",
                                    insufficient_quantity: "Insufficient Quantity",
                                    not_editable: "Event Resources are not currently editable.",
                                },
                                yes_finished: "YES, I'm finished",
                                note_required: "A note is required",
                                no_suggested_resources: "No Suggested Resources",
                                resource_request_denied:
                                    "A resource request for this event was denied. Please contact your system administrator for details.",
                                uncheck_all: "Uncheck All",
                                no_conf_text_edit: "You do not have access to edit confirmation text for this event.",
                                none_selected_parens: "(none selected)",
                                selected_occurrences: "Selected Occurrences:",
                                editing_event: "Editing:",
                                no_report: "No report is available for this event.",
                                date_exclusion: "An occurrence of this event was affected by a date exclusion on ",
                                name: {
                                    max_length_40: "(maximum length is 40 characters)",
                                    not_editable: "Event Name is not currently editable.",
                                    invalid_characters: "Event Name has invalid characters:",
                                    name_required: "Event Name is required.",
                                },
                                find_by: "Find by...",
                                availability_details: {
                                    space_conflict_pending: "Conflict with pending reservation:",
                                    space_conflict_blackout: "Conflict with location blackout period:",
                                    space_conflict_reservation: "Conflict with existing event:",
                                    conflicts: "Conflicts",
                                    hover_prompt: "Hover over conflict icons for more information.",
                                    occurrence: "Occurrence",
                                    none: "(none)",
                                    quantity_requested: "Requested",
                                    space_conflict_hours: "Conflict with location hours:",
                                    space_available: "This occurrence has no conflicts!",
                                    quantity_available: "Available",
                                },
                                no_selected_roles: "No contact roles have been selected.",
                                no_suggested_orgs: "No Suggested Organizations",
                                already_primary:
                                    "This organization is currently selected as the primary organization for this event.",
                                button_cancel: "Cancel",
                                note: "Note:",
                                sponsor: {
                                    additional_not_editable: "Additional Organizations are not currently editable.",
                                    org_names: "Index",
                                    suggested_orgs: "Suggested Organizations",
                                    search_by_org_name: "Search by Organization Name:",
                                    select_your_orgs: "Select Your Organizations",
                                    warn_msg:
                                        "This Organization may not be allowed to schedule events. Contact your System Administrator for more information.",
                                    rating: "Rating",
                                    no_org_types: "No Organization Types",
                                    your_fav_orgs: "Your Starred Organizations",
                                    no_fav_orgs: "No Starred Organizations",
                                    selected_org: "Selected Organization",
                                    must_have_org: "Event must have a primary organization.",
                                    select_your_org: "Select Your Organization",
                                    no_matching_orgs: "No Matching Organizations",
                                    org_categories: "Categories",
                                    org_types: "Types",
                                    no_suggested_orgs: "No Suggested Organizations",
                                    deny_msg:
                                        "This Organization is not currently allowed to schedule events. Contact your System Administrator for more information.",
                                    selected_orgs: "Selected Organizations",
                                    org_rating_deny: "This organization cannot schedule non-draft events.",
                                    no_org_categories: "No Organization Categories",
                                    primary_not_editable: "Primary Organization is not currently editable.",
                                },
                                event_in_past:
                                    "This event is in the past. Registration may only be enabled for events in the future.",
                                roles: "Contact Roles",
                                awaiting_dates: "Awaiting Date Selection...",
                                conf_text_required: "Confirmation text is required",
                                description: {
                                    source_note:
                                        'To embed HTML code directly (such as a YouTube video), switch the editor to "Source" view.',
                                    not_editable: "Event Description is not currently editable.",
                                    description_required: "Event Description is required.",
                                    publisher_note:
                                        "<span><b>Note:</b> Some HTML tags (including TABLE tags) are not supported by <b>25Live Publisher</b>.</span>",
                                },
                                choose_evtypes: "Choose an Event Type...",
                                title: {
                                    title_required: "Event title is required.",
                                    title_too_long:
                                        "The title is longer than 120 characters, and will be shortened when the event is saved.",
                                    not_editable: "Event Title is not currently editable.",
                                    max_length_120: "(maximum length is 120 characters)",
                                },
                                close_without_save: "CLOSE without saving",
                                choose_category: "Choose a Category...",
                                simple_signup: "Simple Sign-up/RSVP",
                                check_for_conflicts: "Check for Location/Resource Conflicts?",
                                choose_starred_resources_no_ellipsis: "Choose from Your Starred Resources",
                                use_reg_form: "Use an existing registration form:",
                                custom_attrib_label: "Custom Attributes",
                                print_report: {
                                    close_window: "Close Window",
                                    wait: "Please wait a few moments for a download prompt to appear.",
                                    preparing: "25Live is preparing your report for download.",
                                    refresh_condition: "If a download prompt does not appear within 10 seconds",
                                    refresh_link: "click here to refresh",
                                    config_error:
                                        "25Live requires the R25 WebServices version of reports. Please ensure reports have been properly configured for your site.",
                                },
                                summary: {
                                    active_notifications: "Active Notification Tasks",
                                    more_event_options: "More Event Options",
                                    vcal_actions: "Check vCalendar To Do's",
                                    print_event: "Print Confirmation",
                                    starred_event: "Starred Event (Remove from Starred?)",
                                    edit_text: "Edit",
                                    copy_related: "Copy Related Events",
                                    reg_form: "Attention: Registration Form",
                                    view_details_description:
                                        "View the Event Details page for this event. The full range of actions are available to you from there.",
                                    active_notification: "Active Notification Task",
                                    active_assignment: "Active Assignment Task",
                                    view_details: "View Details",
                                    close_event_description:
                                        "This event will remain locked against any further changes until you click the Close or View Details buttons.",
                                    active_todo: "Active To Do Task",
                                    take_ownership: "Take Ownership of this Event",
                                    unstarred_event: "Add to Starred?",
                                    event_options: "What's Next?",
                                    email_event_description:
                                        "Email the details of this event to its stakeholders or anyone else.",
                                    not_set: "not set",
                                    scheduler: "Scheduler:",
                                    edit_text_reg: "Edit (inc. Event Registration)",
                                    manage_bindings: "Manage Bindings",
                                    event_preferences: "Event Preferences",
                                    active_todos: "Active To Do Tasks",
                                    event_details: "Event Details",
                                    copy_event: "Copy",
                                    manage_relationships: "Manage Related Events",
                                    reg_warning:
                                        "You have modified an event that has a registration form. Please verify that the registration form matches the event updates.",
                                    active_assignments: "Active Assignment Tasks",
                                    changes_made: "... But We Had to Make Some Changes",
                                    edit_success_msg: "This event has been successfully saved.",
                                    creation_success_msg: "This event has been successfully saved.",
                                    event_state: "Event State:",
                                    no_active_tasks: "No Active Tasks",
                                    warning_constraints:
                                        "There are <b>Warning Constraints</b> for the following dates:",
                                    exception_constraints:
                                        "There are <b>Exception Constraints</b> for the following dates:",
                                    event_reference: "Event Reference:",
                                    establish_more_relationships: "More Relationship Options",
                                    requestor: "Requestor:",
                                    event_successfully_saved: "Your event has been successfully saved!",
                                    important: "IMPORTANT!",
                                    event_constraints: "Event Constraints",
                                    create_another: "Create another Event",
                                    messages: {
                                        unknown_explanation:
                                            "We encountered an unexpected error. See the details of this event for more information. Please consult your system administrator for more information.",
                                        exception_explanation:
                                            "Because of an existing restriction in this event's cabinet during certain timeframes, one or all of your occurrences were not scheduled. View event details to see which occurrences were scheduled. Please consult your system administrator for more information.",
                                        resource_limited_hd: "Resource Stock is Limited",
                                        space_approvals_hd: "Locations Can't Be Approved",
                                        warning_explanation:
                                            "One or more of your event occurrences has been labeled with a constraint warning, probably due to another significant event occurring at your institution at the same time. Please consult your system administrator for more information.",
                                        makedraft_explanation:
                                            "We were unable to route your event into a folder, so it was converted into a Draft state. Any locations or resources you assigned have been saved as preferences for this event. This event will not become active until it leaves Draft mode. Please consult your system administrator for more information.",
                                        resource_unavailable_hd: "Resources Unavailable",
                                        space_pref_hd: "Locations Saved as Preference",
                                        space_conflicts_hd: "Location Conflicts",
                                        changedate_hd: "Date Constraint",
                                        exception_hd: "Exception Constraint",
                                        warning_hd: "Constraint Warning",
                                        resource_requested_hd: "Resources Requested",
                                        resource_pref_hd: "Resources Saved as Preference",
                                        space_requested_hd: "Locations Requested",
                                        changedate_explanation:
                                            "Because of date boundary restrictions in this event's cabinet, one or more of your occurrences were not scheduled. Please consult your system administrator for more information.",
                                        makedraft_hd: "Converted to Draft",
                                        unknown_hd: "Unexpected Error",
                                    },
                                    email_event: "Email",
                                    edit_event_description:
                                        "Need to make some more edits to this event? Click this button to start editing.",
                                    event_tasks: "Event Tasks",
                                    create_todo: "Create To Do",
                                },
                                status_required: {
                                    one_field_remaining: "1 Required Field Remaining",
                                    checking_required_fields: "Checking Required Fields...",
                                    all_fields_complete: "All Required Fields Complete",
                                    fields_remaining: "Required Fields Remaining",
                                },
                                or: "OR",
                                also_assign: "Also Assign:",
                                resource_prefs:
                                    "The following resource preferences have been registered for this event:",
                                comments: {
                                    comments_required: "A comment is required",
                                    not_editable: "Event Comments are not currently editable.",
                                },
                                choose_res_index: "Choose a Resource Index...",
                                cancel_close: "Cancel or Close this instance of the Event Form.",
                                requirement: {
                                    no_publish_requirements:
                                        'There are no "Publish to Calendar" options for the selected Event Type.',
                                    quantity: "Quantity",
                                    comments: "Comments",
                                    no_requirements: "There are no Requirements for the selected Event Type.",
                                    not_editable: "Event Requirements are not currently editable.",
                                },
                                creating_event: "Creating:",
                                choose_suggested_evtypes: "Choose from Suggested Event Types...",
                                search_results: "Search Results",
                                confirmed_or_tentative:
                                    "Event must have a state of confirmed or tentative to configure registration.",
                                no_notes_edit: "You do not have access to edit notes for this event.",
                                and_greater: "and greater",
                                custom_attr: {
                                    custom_attr_required: "Custom attributes are required.",
                                    format_999: "This field must match the format 999.99",
                                    mult_custom_attr_required:
                                        "One or more custom attributes are required. Please add values for the required custom attributes.",
                                    enter_number: "Enter the appropriate number (can include decimals):",
                                    enter_date_time: "Enter the date and time:",
                                    no: "No",
                                    no_images: "No Images",
                                    images: "Images",
                                    enter_date: "Enter the date:",
                                    yes: "Yes",
                                    enter_whole_num: "Enter the appropriate whole number (integer, no decimals):",
                                    enter_alpha_numeric: "Enter up to 80 alphanumeric characters:",
                                    no_custom_attr: "There are no custom attributes for this event type.",
                                    enter_time: "Enter the time:",
                                    select_your_image: "Select Your Image",
                                    selected_image: "Selected Image",
                                    number_example: "Examples: 8.25, 10.00, 12",
                                    format_integer: "This field must contain an integer",
                                    enter_file_path: "Enter the path name of a file, URL or command line:",
                                    no_local_edit:
                                        "The selected Event Type does not allow changes to Custom Attributes.",
                                    not_editable: "Custom Attributes are not currently editable.",
                                    whole_num_example: "Examples: 8, 10, 12",
                                },
                                category: {
                                    inactive: "This category has been deactivated.",
                                    no_available_categories: "No Available Categories",
                                    uncheck_all: "Uncheck All",
                                    check_all: "Check All",
                                    not_editable: "Event Categories are not currently editable.",
                                },
                                email_details: "Email details of this event.",
                                choose_orgs: "Choose an Organization...",
                                button_next: "Next",
                            },
                            tooltips: {
                                previous_options: "Previous Options",
                                sponsor: {
                                    remove_from_starred_orgs: "Remove this from Your Starred Organizations",
                                    add_to_starred_orgs: "Add this to Your Starred Organizations",
                                    sponsor_help:
                                        "<div>For the primary event sponsor, and for any additional organization(s) sponsoring the event, choose from a list of Your Starred Organizations or Search by organization name. Alternatively, you can browse organizations by selecting among Index of names, organization Types, or Categories.</div><div>You may not be able to assign an organization that has a poor customer rating.</div><div>To search for an organization, click 'Search' in the 'Find by' list, then enter all or the first part of the organization name. Click the 'Search' button. Choose an organization from the Choose from... list.</div><div>Click Create New Organization to add an organization to the database.</div><div>The Selected Organization can be added to (or removed from) Your Starred Organizations by toggling its star icon.</div>",
                                },
                                registration_online:
                                    'Create a registration form here (or provide the form code ID of an "existing" form already created by CollegeNET) to allow drop-in and other users to register and potentially pay for this event online, from 25Live or a Publisher calendar.',
                                keyboard_shortcuts: "View keyboard shortcuts for event creation and editing",
                                headcount: {
                                    headcount_registered_help:
                                        "<div>Enter the registered head count, if known. You must enter a value other than zero (0) if the head count is required. A warning in the Event Status area lets you know when head count exceeds location capacity; if you've assigned multiple locations, the capacity of all locations is summed to determine whether head count is accommodated.</div>",
                                    headcount_help:
                                        "<div>Enter the expected and registered head counts, if known. You must enter a value other than zero (0) if the head count is required. A warning in the Event Status area lets you know when head count exceeds location capacity; if you've assigned multiple locations, the capacity of all locations is summed to determine whether head count is accommodated.</div>",
                                    headcount_expected_help:
                                        "<div>Enter the expected head count, if known. You must enter a value other than zero (0) if the head count is required. A warning in the Event Status area lets you know when head count exceeds location capacity; if you've assigned multiple locations, the capacity of all locations is summed to determine whether head count is accommodated.</div>",
                                },
                                save_this_event: "Save this event",
                                button_save: "Finish Editing this Event",
                                title: {
                                    title_help:
                                        "<div>Enter a longer, more formal name for the event, if applicable.</div><div>The title will be displayed in event details exactly as you enter it.</div>",
                                },
                                confirmation_text:
                                    "Enter any comments relevant to this event reservation. These comments will be visible in the Event Confirmation report.",
                                space: {
                                    location_not_available:
                                        "This Location has conflicts for some or all of the currently selected Dates and Times.",
                                    remove_from_starred_locations: "Remove this from Your Starred Locations",
                                    no_date_time:
                                        "Until you select a Date and Time, we cannot determine if this Location has conflicts.",
                                    click_to_remove_location: "Click to remove this location from the event.",
                                    view_location_details: "Click to view location details and availability.",
                                    add_to_starred_locations: "Add this to Your Starred Locations",
                                    cannot_remove_location:
                                        "You do not have sufficient permissions to remove this location from the event.",
                                    location_can_be_shared:
                                        "This Location can be shared with an event already scheduled during the currently selected Dates and Times. View this Location's Availability for details.",
                                    space_help:
                                        "<div>Choose from a list of Your Starred Locations or Search by location name. Alternatively, you can browse locations by selecting among Location Searches, Index of names, Categories, Features, Layouts, or Capacities.</div><div>To search for a location, click 'Search' in the 'Find by' list, then enter all or the first part of the location short or formal name. Click the 'Search' button. A checkbox under the Choose from... list lets you hide or show unavailable locations.</div><div>You can hover over a location name to learn more about it.</div><div>You may assign multiple locations by event occurrence. Click Modify Selected Occurrences to complete details for each occurrence of a location assignment. You may elect to share the location with other event activities, if appropriate.</div><div><b>Note: </b>You can modify selected location instructions and layout, and they will be retained even if the location is saved as an event preference only. You cannot \"unshare\" a shared location after it's been saved.</div><div>If you don't have permission to save your selected location(s), your preferences are saved with the event so they're available to the 25Live user with the required scheduler permissions.</div><div>A Selected Location can be added to (or removed from) Your Starred Locations by toggling its star icon.</div>",
                                    location_available:
                                        "This Location has no conflicts for all of the currently selected Dates and Times.",
                                    loading_availability_details: "Loading Location Availability Details...",
                                    location_unavailable:
                                        "This Location has conflicts during some or all of the currently selected Dates and Times. Click for details.",
                                    checking_location_availability: "Checking for conflicts...",
                                },
                                button_previous: "Return to the Previous screen",
                                description_help:
                                    "<div>Provide description highlights to inform the potential audience about the event.</div><div><b>Note:</b> If your description includes HTML and your event will be sent to 25Live Publisher calendars, be sure to check the note on HTML tags beneath the text entry area.</div>",
                                datetime: {
                                    datetime_help:
                                        '<div>Select the date(s), and type the start/end times for the event. For example, for a 12-hour clock, you can select the current time and type "2p", then press Tab to enter 2:00 pm.</div><div>If the event ends at midnight or later, be sure to change the date in the End field before specifying the End time.</div><div>Expand the links under the start/end times to enter pre-/post-event and setup/takedown durations.</div><div>To select additional dates, change "No, Does Not Repeat" to specify a repeating meeting pattern (for example, Weekly) or, to select random dates, choose "Repeats Ad Hoc." If you\'ve already selected locations/resources for the event and you\'re changing dates/times, be sure to check for location resource conflicts. (This check happens automatically when you change tabs or save the event.)</div><div>You can add comments and cancel individual occurrences as needed.</div>',
                                },
                                avail_check:
                                    "Click here to Check Availability of your selected Locations and Resources against your selected Date and Time.",
                                refresh_results: "Refresh Results",
                                internal_notes:
                                    "Enter comments to be seen only by other individuals with appropriate functional-level security access (to this field and content) and object-level security access (to this event).",
                                state: {
                                    state_help:
                                        "<div>Choose the current completion status of the event.</div><div>Scheduling office policies determine the significance of the various event states.</div><div>System security determines who can edit the event state.</div>",
                                },
                                more_options: "More Options",
                                resource: {
                                    checking_resource_availability: "Checking for conflicts...",
                                    resource_available:
                                        "This Resource has no conflicts for all of the currently selected Dates and Times. Click for details.",
                                    resource_not_available:
                                        "This Resource has conflicts for some or all of the currently selected Dates and Times.",
                                    loading_availability_details: "Loading Resource Availability Details...",
                                    click_to_remove_resource: "Click to remove this resource from the event.",
                                    view_resource_details: "Click to view resource details and availability.",
                                    no_date_time:
                                        "Until you select a Date and Time, we cannot determine if this Resource has conflicts.",
                                    resource_help:
                                        "<div>Choose from a list of Your Starred Resources or Search by resource name. Alternatively, you can browse resources by selecting among Resource Searches, Index of names, or Categories.</div><div>To search for a resource, click 'Search' in the 'Find by' list, then enter all or the first part of the resource name. Click the 'Search' button. A checkbox under the Choose from... list lets you hide or show unavailable resources.</div><div>You can hover over a resource name to learn more about it.</div><div>You may assign multiple resources by event occurrence. Click Modify Selected Occurrences to complete details for each occurrence of a resource assignment.</div><div><b>Note: </b>You can edit selected resource instructions or quantities, and they will be retained even if the resource is saved as an event preference only.</div><div>If you don't have permission to save your selected resource(s), your preferences are saved with the event so they're available to the 25Live user with the required scheduler permissions.</div><div>A Selected Resource can be added to (or removed from) Your Starred Resources by toggling its star icon.</div>",
                                    resource_unavailable:
                                        "This Resource no conflicts during some or all of the currently selected Dates and Times. Click for details.",
                                    add_to_starred_resources: "Add this to Your Starred Resources",
                                    remove_from_starred_resources: "Remove this from Your Starred Resources",
                                    cannot_remove_resource:
                                        "You do not have sufficient permissions to remove this resource from the event.",
                                },
                                recheck_availability: "Re-Check Availability",
                                evtype: {
                                    add_to_fav_evtypes: "Add this to Your Starred Event Types",
                                    click_to_remove_event_type: "Click to remove this event type from the event.",
                                    evtype_help:
                                        "<div>Choose the event type that is most appropriate for your event--from a list of Your Starred Event Types or from All Event Types.</div><div>The Selected Event Type can be added to (or removed from) Your Starred Event Types by toggling its star icon.</div><div>You cannot change the event type once the event is saved.</div>",
                                    remove_from_fav_evtypes: "Remove this from Your Starred Event Types",
                                },
                                cancel_close: "Cancel or Close this instance of the Event Form.",
                                requirement: {
                                    requirement_help:
                                        "<div>Requirements are available only after you've specified the event type.</div><div>Check the box next to any of the items appropriate to your event.</div><div>Enter any additional instructions or comments as needed. These will be visible only to the relevant workflow assignee(s), in their Task List view of the event.</div>",
                                    calendar_help:
                                        "<div>Calendar options are available only after you've specified the event type.</div><div>Check the calendar(s) where the event needs to be published.</div><div>Enter any additional instructions or comments as needed. These will be visible only to the relevant workflow assignee(s), in their Task List view of the event.</div>",
                                },
                                description_html_help:
                                    "<div>Provide descriptive content to inform your potential audience about the event.</div><div>Use the Editor tab to enter and format text in a What You See Is What You Get (WYSIWYG) environment.</div><div>Use the Source tab to enter raw HTML, or to view the tagged results of content entered on the Editor tab. You must specify image content manually on the Source tab.</div><div><b>Note:</b> If your event will be sent to 25Live Publisher calendars, be sure to check the note on HTML tags beneath the text entry area.</div>",
                                help: "Get help with creating and editing events.",
                                registration_otherwise: "If the event does not require registration, select Disabled.",
                                registration_create_form:
                                    "To create a registration form, you'll need to complete content and payment details for the online form. Let the cursor hover over a field to learn how to use it.",
                                comments_help:
                                    "Enter any comments relevant to this event reservation. These comments will be visible in the Event Confirmation report, and may be displayed in the Publisher.",
                                name: {
                                    name_help:
                                        "<div>Type a name that's clear and descriptive, for your own purposes and to help others who might be interested in the event.</div>",
                                },
                                confirmation_report: "Print a confirmation report for this event.",
                                custom_attr: {
                                    custom_attr_help:
                                        "<div>Custom attributes are available only after you've specified the event type. Some event types may not use custom attributes. Some or all custom attributes may be required before you can save the event.</div><div>Check the box next to an attribute name to select it for the event, then provide the appropriate value.</div><div>To remove a custom attribute from the event, uncheck the box next to the attribute name.</div><div>You may need to scroll down to see all options.</div><div>You can expand or collapse any custom attribute by clicking the +/- toggle on the right.</div>",
                                },
                                category: {
                                    category_help:
                                        "<div>Categories are available only after you've specified the event type.</div><div>Select any categories that apply to your event. Any categories already checked are based on the selected event type.</div><div>You may need to scroll down to see all options.</div>",
                                },
                                contacts: {
                                    remove_from_starred_contacts: "Remove this Contact from Your Starred Contacts",
                                    contacts_help:
                                        "<div>Contact roles are available only after you've specified the event type.</div><div>To assign an individual to a selected contact role, check the role name, then choose from a list of Your Starred Contacts or Selected Organization Contacts, or Search by contact name.</div><div>To search for a contact, click 'Search' in the 'Find by' list, then enter any part of the contact's first or last name. Click the 'Search' button. Choose a contact from the Choose from... list.</div><div>Click Create New Contact to add a contact to the database.</div><div>The Selected Contact can be added to (or removed from) Your Starred Contacts by toggling its star icon.</div>",
                                    add_to_starred_contacts: "Add this Contact to Your Starred Contacts",
                                },
                                button_next: "Proceed to the Next screen",
                                button_cancel: "Stop Editing this Event",
                            },
                        },
                    },
                    "xmlns:c": "http://www.collegenet.com/c",
                    application: {
                        date: "Date",
                        view_help_tip: "Help with this view",
                        event_problem: "There was a problem with your event. Please try again later.",
                        private: "(Private)",
                        send_to_publisher_tip: "Send events displayed below to the 25Live Publisher.",
                        about: "About",
                        more_info: "More info?",
                        search_name_modal: {
                            add_to_starred: "Add to Your Starred Searches",
                            keep_as_starred: "Keep as one of Your Starred Searches",
                            header: "Enter Search Name",
                            keyword_input_label: "Keyword Search Name",
                            input_label: "Search Name",
                        },
                        choose_columns_tip: "Turn individual columns on and off to customize the display.",
                        not_active: "[Not Active]",
                        preparing_report: "Preparing Your Report...",
                        search: {
                            no_users_to_share:
                                "You can only share searches with 25Live users, and none were returned by your search. Please contact your administrator for help accessing user information.",
                            no_search_term: "You have not entered a search term.",
                            share_success: "You have successfully shared a search with:",
                            or_text: "or",
                            search_by_keyword: "Search by Keyword",
                            shared_with: "was shared with you",
                            search: "Search",
                            resource_share_notification: "shared the following Resource search with you in 25Live:",
                            org_share_notification: "shared the following Organization search with you in 25Live:",
                            share_description:
                                "Select the 25Live user contacts to receive a copy of your saved search, which they can use as is or modify as needed.",
                            share_fail: "Your search was not shared. Please try again later.",
                            share: "Share",
                            task_share_notification: "shared the following Task search with you in 25Live:",
                            share_success_status: "You have successfully shared your search.",
                            more_search_options: "More Search Options",
                            copy_success: "Search was successfully copied.",
                            copy_problem: "There was a problem copying your search. Please try again later.",
                            too_short_search_term: "Search terms must be at least two characters long.",
                            default_search_text: "Enter a Search Term...",
                            location_share_notification: "shared the following Location search with you in 25Live:",
                            none_available: "None Available",
                            done: "Done",
                            no_criteria_provided: "Please enter or select some criteria before starting your search.",
                            fewer_search_options: "Fewer Search Options",
                            with: "to:",
                            email_fail: "But for some reason notification emails were not sent to",
                            event_share_notification: "shared the following Event search with you in 25Live:",
                            retrieving_events_for: "Retrieving events for:",
                            rename_hint:
                                "Hint: Rename this search to a name that you'll remember by clicking the Rename button on the Pre-Defined Searches tab in 25Live.",
                        },
                        send_to_publisher: "Send to Publisher",
                        views: {
                            calendar: "Calendar",
                            details: "Details",
                            availability: "Availability",
                            list: "List",
                        },
                        all: "All",
                        daterange: {
                            through: "through",
                            or_text: "OR",
                            search_current_future_dates: "Search Current and Future Dates",
                            update_calendar: "Update Calendar",
                            tooltip:
                                "Click to select a start and end date. If present, use the arrows to shift the date back or forward.",
                            current_future_dates: "Current and Future Dates",
                            update_list: "Update List",
                            all_dates: "All Dates",
                            select_date_range: "Select a Date Range",
                            search_all_dates: "Search All Dates",
                        },
                        months: {
                            month: [
                                {
                                    name: "January",
                                    abbreviation: "Jan",
                                },
                                {
                                    name: "February",
                                    abbreviation: "Feb",
                                },
                                {
                                    name: "March",
                                    abbreviation: "Mar",
                                },
                                {
                                    name: "April",
                                    abbreviation: "Apr",
                                },
                                {
                                    name: "May",
                                    abbreviation: "May",
                                },
                                {
                                    name: "June",
                                    abbreviation: "Jun",
                                },
                                {
                                    name: "July",
                                    abbreviation: "Jul",
                                },
                                {
                                    name: "August",
                                    abbreviation: "Aug",
                                },
                                {
                                    name: "September",
                                    abbreviation: "Sep",
                                },
                                {
                                    name: "October",
                                    abbreviation: "Oct",
                                },
                                {
                                    name: "November",
                                    abbreviation: "Nov",
                                },
                                {
                                    name: "December",
                                    abbreviation: "Dec",
                                },
                            ],
                        },
                        address: {
                            zip: "Zip/Post",
                            country: "Country",
                            address: "Address",
                            phone: "Phone",
                            city: "City",
                            state: "State/Province",
                            fax: "Fax",
                        },
                        edit: "Edit",
                        start_over: "Start Over",
                        go: "GO",
                        separated: "Separated",
                        refresh: "Refresh",
                        same_day_view: "Same-Day Event View",
                        dates: "Dates",
                        loading: "Loading...",
                        choose_columns: "Choose Visible Columns",
                        done: "Done",
                        report_formats: {
                            excel: "Excel",
                            pdf: "PDF",
                            rtf: "Rich Text Format",
                            html: "HTML",
                            text: "Text",
                        },
                        save_search: "Save Search",
                        overlapping: "Overlapping",
                        days: {
                            day: [
                                {
                                    name: "Sunday",
                                    abbreviation: "Sun",
                                },
                                {
                                    name: "Monday",
                                    abbreviation: "Mon",
                                },
                                {
                                    name: "Tuesday",
                                    abbreviation: "Tue",
                                },
                                {
                                    name: "Wednesday",
                                    abbreviation: "Wed",
                                },
                                {
                                    name: "Thursday",
                                    abbreviation: "Thu",
                                },
                                {
                                    name: "Friday",
                                    abbreviation: "Fri",
                                },
                                {
                                    name: "Saturday",
                                    abbreviation: "Sat",
                                },
                            ],
                        },
                        guide_prompt: "What is this view?",
                        browse: {
                            earliest_latest_conflict: "Earliest Start Date cannot be later than Latest End Date.",
                            search_groupings: "Search Groupings",
                            unpopulated_criteria:
                                "You have unpopulated criteria ('None Selected'). Please populate or remove the criteria.",
                            new_search: "New Search",
                            reservation_types: {
                                constraint_warning_dates: "Constraint - Warning Dates",
                                constraint_exclude_dates: "Constraint - Exclude Dates",
                                reservation: "Reservation",
                                constraint_include_dates: "Constraint - Include Dates",
                            },
                            task_criteria_instructions:
                                "At least one task state and one task type are required, but you may choose any combination of task types or states, or all of them. By default, task searches return only tasks due today.",
                            search_failure: "There was a problem executing your search! Please try again.",
                            none: "None",
                            layouts: "Layouts",
                            events_only_note: "Note: Cabinets and Folders are not returned in 25Live.",
                            features: "Features",
                            read_only: "Read-Only",
                            capacity_conflict: "Maximum Capacity cannot be less than Minimum Capacity.",
                            hide_criteria: "Hide Criteria",
                            matching_all: "Matching All",
                            partitions: "Partitions",
                            searches: {
                                assignments: "Assignment Tasks",
                                layouts: "Layouts",
                                specific_locations: "Specific Locations",
                                preferred_locations: "Preferred Locations",
                                starred_events: "Your Starred Events",
                                relationships: "Relationships",
                                location_assignments: "Location Assignments",
                                resource_assignments: "Resource Assignments",
                                organizations_search: "Organization Searches",
                                events: "Events",
                                between: "Between",
                                outstanding_todos: "Outstanding To Do Tasks",
                                headcount_registered: "Head Count, Registered",
                                max_capacity: "Maximum Capacity",
                                date_params: "Dates",
                                organization_name: "Organization Name",
                                missing_location_assignments: "Missing Location Assignments",
                                location_name: "Location Name",
                                assigned_params: "Assignments",
                                alien_uid: "Alien UID",
                                following: "Following",
                                notifications: "Notification FYIs",
                                end_date: "Latest End Date",
                                date_pattern: "Reservation Date Pattern",
                                other_criteria_first: "You must add other criteria first before selecting this option.",
                                unread: "Flagged Tasks Only",
                                outstanding_vcal_tasks: "Outstanding vCalendar Tasks",
                                reservation_type: "Reservation Type",
                                day_or_days: "Day(s)",
                                has_bound_events: "Has Bound Events",
                                fill_ratio: "Fill Ratio",
                                location_assignments_search: "Location Assignments (via Search)",
                                outstanding_vcal_export: "Outstanding vCalendar To Do Tasks: Export",
                                headcount_expected: "Head Count, Expected",
                                following_and_before: "Following and Before",
                                outstanding_vcal_exceeds_headcount:
                                    "Outstanding vCalendar To Do Tasks: Exceeds Head Count",
                                categories: "Categories",
                                schedule25_key: "Schedule25 Key",
                                specific_date: "Specific Date",
                                occurrence_dates: "Occurrence Dates",
                                min_capacity: "Minimum Capacity",
                                start_date: "Earliest Start Date",
                                create_date: "Create Date",
                                assigned_to: "Assigned To",
                                structure_element: "Structure Element",
                                address: "Address",
                                number_of_days: "Number of Days",
                                requirement_notifications: "Requirement Notifications",
                                outstanding_assignments: "Outstanding Assignment Tasks",
                                end_time: "End Time",
                                modified_since: "Modified Since",
                                specific_events: "Specific Events",
                                custom_attribute: "Custom Attribute",
                                reservation_definitions_all: "All Reservation Definitions Options",
                                task_types: "Task Types",
                                accounting_code: "Accounting Code",
                                task_states: "Task States",
                                reservation_definitions: "Reservation Definitions",
                                include_related_locations: "Include Related Locations",
                                days: "Days",
                                todos: "To Do Tasks",
                                resource_name: "Resource Name",
                                starred_organizations: "Your Starred Organizations",
                                events_search: "Events (via Search)",
                                contacts: "Contacts",
                                state_canceled: "Cancelled",
                                organization_assignments_search: "Organization Assignments (via Search)",
                                state_granted: "Completed",
                                before: "Before",
                                specific_resources: "Specific Resources",
                                reference: "Reference",
                                features: "Features",
                                location_relationships: "Location Relationships",
                                approvals: "Authorization Tasks",
                                event_type_notification: "Event Type Notifications",
                                reservation_name: "Reservation Name",
                                from: "From",
                                cabinets: "Cabinets",
                                locations_search: "Locations (via Search)",
                                all_types: "All Task Types",
                                resource_notifications: "Resource Notifications",
                                partitions: "Partitions",
                                notification_types: "Notification Types",
                                has_related_events: "Has Related Events",
                                outstanding_approvals: "Outstanding Approval Tasks",
                                event_title: "Event Title",
                                general_all: "All General Options",
                                organization_assignments: "Organization Assignments",
                                start_time: "Start Time",
                                space_notifications: "Space Notifications",
                                event_name: "Event Name",
                                outstanding_vcal_needs_space: "Outstanding vCalendar To Do Tasks: Needs Space",
                                assigned_by: "Assigned By",
                                and_text: "and:",
                                starred_resources: "Your Starred Resources",
                                org_notifications: "Organization Notifications",
                                specific_organizations: "Specific Organizations",
                                state_active: "Outstanding",
                                states: "Event States",
                                general: "General",
                                ratings: "Ratings",
                                today: "Today",
                                assigned_locations: "Assigned Locations",
                                organization_types: "Organization Types",
                                resource_assignments_search: "Resource Assignments (via Search)",
                                organization_title: "Organization Title",
                                include_child_events: "Include Child Events",
                                requirements: "Requirements",
                                types: "Event Types",
                                starred_locations: "Your Starred Locations",
                                has_friend_events: "Has Friend Events",
                                due_date: "Due Date Between:",
                                assigned_resources: "Assigned Resources",
                                resources: "Resources",
                                last_modified_by: "Last Modified By",
                                vcal_todos: "vCalendar To Do Tasks",
                                resources_search: "Resources (via Search)",
                                resource_relationships: "Resource Relationships",
                                organizations: "Organizations",
                                until: "Until",
                                outstanding_vcal_todos: "Outstanding vCalendar To Do Tasks",
                                locations: "Locations",
                                state_denied: "Denied",
                                location_formal_name: "Location Formal Name",
                            },
                            select_custom_attribute: "Select a Custom Attribute...",
                            index: "Index",
                            none_available: "None Available",
                            unselected_custom_attribute:
                                "You have not selected a specific Custom Attribute. Please select and populate an attribute, or remove the criteria.",
                            contains: "Contains",
                            infobox_search: {
                                starred_event_searches: "Your Starred Event Searches",
                                browse_resources: "Browse Resources",
                                selected_resources: "Selected Resources",
                                browse_contacts: "Browse Contacts",
                                contact_searches: "Contact Searches",
                                selected_organizations: "Selected Organizations",
                                starred_organization_searches: "Your Starred Organization Searches",
                                search: "Search",
                                organization_searches: "Organization Searches",
                                resource_searches: "Resource Searches",
                                your_starred_contacts: "Your Starred Contacts",
                                select_from: "Select From...",
                                public_location_searches: "Public Location Searches",
                                all_location_searches: "All of Your Location Searches",
                                your_starred_organizations: "Your Starred Organizations",
                                browse_locations: "Browse Locations",
                                selected_contacts: "Selected Contacts",
                                browse_organizations: "Browse Organizations",
                                starred_location_searches: "Your Starred Location Searches",
                                selected_locations: "Selected Locations",
                                go: "Go",
                                your_starred_locations: "Your Starred Locations",
                                done: "Done",
                                selected_events: "Selected Events",
                                all_event_searches: "All of Your Event Searches",
                                all_organization_searches: "All of Your Organization Searches",
                                your_starred_events: "Your Starred Events",
                                browse_events: "Browse Events",
                                public_organization_searches: "Public Organization Searches",
                                all_resource_searches: "All of Your Resource Searches",
                                find_by: "Find By...",
                                public_resource_searches: "Public Resource Searches",
                                your_starred_resources: "Your Starred Resources",
                                public_event_searches: "Public Event Searches",
                                location_searches: "Location Searches",
                                starred_resource_searches: "Your Starred Resource Searches",
                                event_searches: "Event Searches",
                            },
                            include_any: "Include Any",
                            none_selected: "None Selected",
                            and_text: "and",
                            browse_instructions: "Select a Search Grouping (left) to browse those searches.",
                            or_text: "or",
                            no_criteria: "Please add criteria to your search.",
                            retrieving_criteria: "Retrieving Criteria...",
                            last_modified_note: "Enter contact username",
                            starts_with: "Starts With",
                            states: "States",
                            capacity: "Capacity",
                            address_contains: "Address Contains",
                            matching_any: "Matching Any",
                            categories: "Categories",
                            do_not_include: "Do Not Include",
                            include_all: "Include All",
                            types: "Types",
                            buttons: {
                                new_search: "New Search",
                                edit: "Edit",
                                save: "Save",
                                back: "Back",
                                add_criteria: "Add Criteria",
                                run: "Run",
                                load_availability: "Load Availability",
                                delete: "Delete",
                                copy_to: "Share",
                                search: "Search!",
                                reload: "Reload",
                                save_as: "Save As...",
                                rename: "Rename",
                                load_list: "Load List",
                                load_calendar: "Load Calendar",
                            },
                            address_type: "Address Type",
                            sorting_existing_results: "Sorting Existing Results...",
                            search_save_failure: "There was a problem saving your search! Please try again.",
                            no_searches: "No Matching Searches",
                            no_options: "No Options",
                            ends_with: "Ends With",
                            show_criteria: "Show Criteria",
                            organizations: "Organizations",
                            maximum: "Maximum",
                            relationship_types: {
                                subdivision_of: "Subdivision Of",
                                close_to: "Close To",
                                also_assign: "Also Assign",
                                divides_into: "Divides Into",
                                substitute_with: "Substitute With",
                                blocked_by: "Blocked By",
                                friend: "Friend",
                                binding_reservation: "Binding Reservation",
                                content: "Content",
                            },
                            unrecognized: "Unrecognized Search Parameter",
                            show_all_general: "Show All",
                            minimum: "Minimum",
                            criteria_instructions:
                                'Click the "Add Criteria" button to add a search criterion. Enter a value for each input field; click the "pencil" to select value(s) for completing a field. If your search has more than one criterion, use the OR/AND selector to determine whether search results must match ALL your criteria or just ANY one of them.',
                        },
                    },
                    style: "display:none;",
                    id: "Lang",
                    locale: {
                        MONTHS: "January,February,March,April,May,June,July,August,September,October,November,December",
                        DAYS1: "M,T,W,R,F,S,U",
                        DAYS: "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday",
                        CURRENCY_PICTURE: {
                            GB: "Â£#,###,###,##0.00",
                            US: "$#,###,###,##0.00",
                            CA: "$#,###,###,##0.00",
                        },
                        TIMEFMT: {
                            GB: "[H01]:[m01]",
                            US: "[h]:[m01] [PN]",
                            CA: "[h]:[m01] [PN]",
                        },
                        NUMBER_FORMAT: {
                            GB: "1",
                            US: "1",
                            CA: "1",
                        },
                        DATEFMT: {
                            GB: "[D1o] [MNn,3-3] [Y0001]",
                            US: "[MNn,3-3] [D01] [Y0001]",
                            CA: "[Y0001]-[M01]-[D01]",
                        },
                    },
                },
            },
            engine: "accl",
            version: 11,
            createDt: "2016-11-09 23:01:27",
            username: "service25",
        },
    };
}
